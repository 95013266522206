import React, { Component } from 'react';
import { Table, Input, Form, Button } from 'antd'; //npm i antd (instalar)
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarErrorCatch, mostrarMensajeValidacionFooter } from "../../Paginas/Mensajes";
import { expiredToken, cerrarSesion } from "../../Paginas/Autorizacion";
import { esNombreNumeros } from '../../Paginas/Validaciones';
import ModificarHojaServicio from './ModificarHojaServicio';
import BajaHojaServicio from './BajaHojaServicio';
import DetalleHojaServicio from './DetalleHojaServicio';
import BusquedaHojaServicio from './BusquedaHojaServicio';

export default class ListadoHojasServicio extends Component {

    state = {
        data: [],
        paginado: [],
        pagina: 0, // usado para cargar el numero de pagina del paginado en el handleChange el cual invoco al final en el onchange del table
        numPlertys: '', // usado para realizar la busqueda por numero de plertys
        numReclamo: '',
        fecha: ''
    };

    handleChange = async value => { //Obtengo el numero de pagina dentro del paginado
        await this.setState({
            pagina: value
        });
        this.peticionGet();
    }

    pasarUrl = () => { //Esta funcion es para no escribir .then mas de una vez dentro de peticion get
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }
        if (this.state.numReclamo !== '' && this.state.numPlertys === '' && this.state.fecha === '') {
            return axios.get('HojaServicio?numReclamo=' + this.state.numReclamo + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numReclamo === '' && this.state.numPlertys !== '' && this.state.fecha === '') {
            return axios.get('HojaServicio?numPlertys=' + this.state.numPlertys + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numReclamo === '' && this.state.numPlertys === '' && this.state.fecha !== '' ) {
            return axios.get('HojaServicio?fecha=' + this.state.fecha + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numReclamo !== '' && this.state.numPlertys !== '' && this.state.fecha === '') {
            return axios.get('HojaServicio?numReclamo=' + this.state.numReclamo + '&numPlertys=' + this.state.numPlertys + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numReclamo !== '' && this.state.numPlertys === '' && this.state.fecha !== '') {
            return axios.get('HojaServicio?numReclamo=' + this.state.numReclamo + '&fecha=' + this.state.fecha + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numReclamo === '' && this.state.numPlertys !== '' && this.state.fecha !== '') {
            return axios.get('HojaServicio?numPlertys=' + this.state.numPlertys + '&fecha=' + this.state.fecha + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numReclamo !== '' && this.state.numPlertys !== '' && this.state.fecha !== '') {
            return axios.get('HojaServicio?numReclamo=' + this.state.numReclamo + '&numPlertys=' + this.state.numPlertys  + '&fecha=' + this.state.fecha + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numReclamo === '' && this.state.numPlertys === '' && this.state.fecha === '') {
            return axios.get('HojaServicio?pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
    }

    peticionGet = async (numReclamo = '', numPlertys = '', fecha = '') => { //los inicializo porque sino me dice undefined
        if (numReclamo !== '' || numPlertys !== '' || fecha !== '') {
            await this.setState({
                numReclamo: numReclamo,
                numPlertys: numPlertys,
                fecha: fecha,
                pagina: 0 //Aca si no esta en la pagina 0 lo obligo a que este para poder buscar
            });
        } else {
            await this.setState({
                numReclamo: '',
                numPlertys: '',
                fecha: ''
            });
        }
        if (!expiredToken()) {
            this.pasarUrl()
                .then(response => {
                    let hojasServicio = response.data.data
                    hojasServicio.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.
                        let fechaH = moment(new Date(el.fechaServicio));
                        return el.fechaServicio = fechaH.format("DD-MM-YYYY");
                    })
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta                               
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    componentDidMount() {
        this.peticionGet();
    }

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idHojaServicio',
                key: 'idHojaServicio',
                width: '15%',
                sorter: (a, b) => a.idHojaServicio - b.idHojaServicio,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'H. Servicio',
                dataIndex: 'numHojaServicio',
                key: 'numHojaServicio',
                width: '10%',
                sorter: (a, b) => a.numHojaServicio - b.numHojaServicio,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Reclamo',
                dataIndex: 'idReclamo',
                key: 'idReclamo',
                width: '8%',
                sorter: (a, b) => a.idReclamo - b.idReclamo,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Fecha ',
                dataIndex: 'fechaServicio',
                key: 'fechaServicio',
                width: '12%',
                sorter: (a, b) => a.fechaServicio.localeCompare(b.fechaServicio),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Maquina',
                dataIndex: ['unObjreclamo', 'unObjMaquina', 'modelo'],
                key: 'modelo',
                width: '12%',
                sorter: (a, b) => a.unObjreclamo.unObjMaquina.modelo.localeCompare(b.unObjreclamo.unObjMaquina.modelo),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Plertys ',
                dataIndex: 'numPlertys',
                key: 'numPlertys',
                width: '8%',
                sorter: (a, b) => a.numPlertys.localeCompare(b.numPlertys),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Tipo Servicio',
                dataIndex: ['unObjTipoServicioHojaServicio', 'tipoServicio'],
                key: 'tipoServicio',
                width: '12%',
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Tecnico',
                dataIndex: ['unObjTecnico', 'nombre'],
                key: 'nombre',
                width: '18%',
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '50%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarHojaServicio
                                    filaSeleccionada={fila}
                                    listaHojasServicio={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaHojaServicio
                                    filaSeleccionada={fila}
                                    listaHojasServicio={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetalleHojaServicio
                                    filaSeleccionada={fila}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Hojas de Servicio</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>

                </div>
                <div style={{ float: 'right' }}>
                    <Form layout="inline" style={{ width: 340 }}>
                        <Form.Item style={{ width: 340 }}>
                            <BusquedaHojaServicio
                                listaHojaServicio={this.peticionGet}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <br /><br /><br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ total: this.state.paginado.totalRegistros, pageSize: this.state.paginado.registrosPorPagina, onChange: this.handleChange }}
                    columns={columns.filter(col => col.dataIndex !== 'idHojaServicio')}
                    dataSource={this.state.data}
                    rowKey={row => row.idHojaServicio} />
            </div>
        );
    }
}