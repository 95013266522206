import React, { Component } from 'react';
import { Table, Input, Button, Space, Form, Image, Descriptions } from 'antd'; //npm i antd (instalar)
import Highlighter from 'react-highlight-words'; // npm i react-highlight-words (instalar para que aparezcan pintadas en amarillo los resultados del search)
import { SearchOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import get from "lodash.get"; // npm i --save lodash.get
import isequal from "lodash.isequal"; //npm i --save lodash.isequal
import { mostrarErrorCatch, mostrarMensajeValidacionFooter } from "../../Paginas/Mensajes";
import { expiredToken, cerrarSesion } from "../../Paginas/Autorizacion";
import { esNombreNumeros, primeraMayuscula } from '../../Paginas/Validaciones';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import CrearProducto from './CrearProducto';
import ModificarProducto from './ModificarProducto';
import BajaProducto from './BajaProducto';
import DetalleProducto from './DetalleProducto';

export default class ListadoProductos extends Component {

    state = {
        searchText: '',
        searchedColumn: '',
        data: [],
        tipo: [],
        paginado: [],
        pagina: 0, // usado para cargar el numero de pagina del paginado en el handleChange el cual invoco al final en el onchange del table
        tipoProducto: '', // usado para realizar la busqueda por nombre
        validacion: false,
        existe: false,
    };

    validacionForm = async () => {
        if (!esNombreNumeros(this.state.tipoProducto)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    handleChange = async value => { //Obtengo el numero de pagina dentro del paginado
        await this.setState({
            pagina: value
        });
        this.peticionGet();
    }

    handleChangeSearch = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            [name]: value
        });
        this.validacionForm();
    }

    onSearch = async value => {
        await this.setState({
            tipoProducto: value
        });
        if (!this.state.validacion && this.state.pagina === 0) { // Debo validar ademas de que ingrese valores correctos que se encuentre en la pagina 0 porque si estoy en la pagina 2 por ej y escribo un nombre no lo va a encontrar            
            this.peticionGet();
        }
        else {
            this.setState({ //Aca si no esta en la pagina 0 lo obligo a que este para poder buscar
                pagina: 0
            })
            this.peticionGet();
        }
    }

    obetenerTipoProducto = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios.get('TipoProducto', config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js        
                .then(response => {
                    this.setState({
                        tipo: response.data.data,
                    }); // Cargo en data los resultados de la consulta                           
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    pasarUrl = () => { //Esta funcion es para no escribir .then mas de una vez dentro de peticion get        
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }

        this.state.tipo.map((tipoProducto) => {
            return tipoProducto.descripcion === primeraMayuscula(this.state.tipoProducto) ?
                this.state.existe = true
                :
                ''
        });

        if (this.state.existe) {
            return axios.get('Producto?descripcionProducto=' + this.state.tipoProducto + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        } else {
            return axios.get('Producto?pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
    }

    peticionGet = () => {
        if (!expiredToken()) {
            this.pasarUrl()
                .then(response => {
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta 
                    this.state.existe = false // se coloca en falso en caso que no se escriba la palabra completa, sino da error de base de datos
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    componentDidMount() {
        this.peticionGet();
        this.obetenerTipoProducto();
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => { //hay que modificar con respecto al original (ListadoRol) , para que busque dentro de unObjRol
            return get(record, dataIndex)
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text => { //hay que modificar con respecto al original (ListadoRol) , para que busque dentro de unObjRol
            return isequal(this.state.searchedColumn, dataIndex) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            );
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idProducto',
                key: 'idProducto',
                width: '15%',
                ...this.getColumnSearchProps('idProducto'),
                sorter: (a, b) => a.idProducto - b.idProducto,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Nombre',
                dataIndex: 'titulo',
                key: 'titulo',
                width: '20%',
                ...this.getColumnSearchProps('titulo'),
                sorter: (a, b) => a.titulo.localeCompare(b.titulo),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Precio $',
                dataIndex: 'precio',
                key: 'precio',
                width: '15%',
                ...this.getColumnSearchProps('precio'),
                sorter: (a, b) => a.precio - b.precio,
                sortDirections: ['descend', 'ascend'],
                render: fila => (fila).toFixed(2),
            },
            {
                title: 'Tipo',
                dataIndex: ['unObjTipoProducto', 'descripcion'],
                key: 'descripcion',
                width: '20%',
                ...this.getColumnSearchProps(['unObjTipoProducto', 'descripcion']),
                sorter: (a, b) => a.unObjTipoProducto.descripcion.localeCompare(b.unObjTipoProducto.descripcion),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width: '5%',
                render: fila => (fila === false) ? <CloseCircleTwoTone twoToneColor="#cf1322" /> : <CheckCircleTwoTone twoToneColor="#52c41a" />
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '50%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarProducto
                                    filaSeleccionada={fila}
                                    listaProductos={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaProducto
                                    filaSeleccionada={fila}
                                    listaProductos={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetalleProducto
                                    filaSeleccionada={fila}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Productos</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>
                    <CrearProducto
                        listaProductos={this.peticionGet}
                        datos={this.state.data} />
                </div>
                <div style={{ float: 'right' }}>
                    <Form layout="inline" style={{ width: 340 }}>
                        <Form.Item style={{ width: 340 }}>
                            <Input.Search
                                allowClear style={{ width: '100%' }}
                                name="tipoProducto"
                                enterButton
                                type="text"
                                id="tipoProducto"
                                size="small"
                                maxLength={70}
                                placeholder="Buscar Producto X Tipo"
                                onSearch={this.onSearch}
                            >
                            </Input.Search>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'Solo se aceptan letras y numeros')}
                        </Form.Item>
                    </Form>
                </div>
                <br /><br /><br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ total: this.state.paginado.totalRegistros, pageSize: this.state.paginado.registrosPorPagina, onChange: this.handleChange }}
                    columns={columns}
                    dataSource={this.state.data}
                    rowKey={row => row.idProducto} />
            </div>
        );
    }
}