import React, { Component } from 'react'
import '../../Estilos/estilos.css';
import { Descriptions, notification } from 'antd'; //npm i antd (instalar)
import { InfoCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)

export default class DetalleCliente extends Component {

    openNotification = () => {
        const args = {
            description:
                <Descriptions size="small" column={1} title="Cliente - Ficha" contentStyle={{ color: "slategray" }}>

                    <Descriptions.Item label="Número" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.idCliente} </Descriptions.Item>
                    <Descriptions.Item label="Rut" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.rut}</Descriptions.Item>
                    <Descriptions.Item label="Nombre" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.nombre}</Descriptions.Item>                    
                    <Descriptions.Item label="Direccion" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.direccion}</Descriptions.Item>                   
                    <Descriptions.Item label="Telefono" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.telefono}</Descriptions.Item>
                    <Descriptions.Item label="Correo" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.email}</Descriptions.Item>

                </Descriptions>,
            duration: 0,
        };
        return (
            notification.open(args)
        );
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <InfoCircleTwoTone
                    twoToneColor="#7cb305"
                    style = {{fontSize:'15px'}}                    
                    onClick={() => this.openNotification()} />                   
            </div>
        )
    }
}