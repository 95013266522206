import { Component } from "react";
import { Link } from 'react-router-dom'; //npm install react-router-dom (instalar)
import '../Estilos/estilos.css';
import 'antd/dist/antd.css'; //npm i antd (instalar)
import { Form, Input, Button, Image } from 'antd'; //npm i antd (instalar)
import logo from '../Logos/logo.png';
import { UserOutlined, LockOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaLogin } from "./Mensajes";
import { expiredToken } from './Autorizacion';
import { Navigate } from 'react-router';

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hayDatosform: false,
            form: {
                usuario: '',
                contrasenia: ''
            },
            token: false
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
        });
        this.haydatosFormulario()
    }

    iniciarSesion = async () => {
        if (this.state.hayDatosform) {
            await axios.post('AAToken', this.state.form)
                .then(response => {
                    localStorage.setItem('Token', response.data.token);
                    localStorage.setItem('Usuario', this.state.form.usuario)
                    this.setState({
                        token: true
                    });

                }).catch(error => {
                    mostrarAlertaLogin('Nombre o contraseña invalidos');
                });
        }

    }

    haydatosFormulario = async () => {
        if (this.state.form.usuario !== '' && this.state.form.contrasenia !== '') {
            await this.setState({ hayDatosform: true });
        }
        else {
            await this.setState({ hayDatosform: false });
        }
    }

    render() {
        if (!expiredToken()) {
            return <Navigate to='/PaginaPrincipal' />
        }

        if (this.state.token) {
            return <Navigate to='/PaginaPrincipal' />
        }

        return (
            <div style={{ backgroundColor: '#001529', height: '100vh' }}>
                <div className="centrarLogin">
                    <Link to="/">
                        <Image
                            src={logo}
                            preview={false} />
                    </Link>
                    <p></p>
                    <Form
                        name="normal_login"
                        className="login-form"
                    >
                        <Form.Item>
                            <Input
                                name="usuario"
                                type="text"
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="usuario"
                                onChange={this.handleChange}
                                required />
                        </Form.Item>
                        <Form.Item>
                            <Input
                                name="contrasenia"
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" onClick={() => this.iniciarSesion()}>
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}