import {Alert, message } from 'antd'; //npm i antd (instalar)

function mostrarAlertaLogin(nombre) {
    return (
        message.error({
            content: nombre,
            className: 'custom-class',
            style: {
                marginTop: '5vh',

            },
        }))
}

function mostrarAlertaExito() {
    return (
        message.success({
            content: 'Ingreso exitoso',
            className: 'custom-class',
            style: {
                marginTop: '5vh',

            },
        }))
}

function mostrarAlertaModificar(nombre) { // Luego de descargar sweetalert lo configuramos
    return (
        message.success({
            content: 'Modificacion exitosa',
            className: '',
            style: {
                marginTop: '5vh',
            },
        }))
}

function mostrarAlertaError(error) { // Luego de descargar sweetalert lo configuramos
    return (
        message.error({
            content: error,
            className: 'custom-class',
            style: {
                marginTop: '5vh',
            },
        }))
}

function mostrarErrorCatch() { // Luego de descargar sweetalert lo configuramos
    return (
        message.error({
            content: 'Ha ocurrido un error inesperado, intente nuevamente',
            className: 'custom-class',
            style: {
                marginTop: '5vh',
            },
        }))
}

function mostrarExisteEnOtraTabla(error) { // Luego de descargar sweetalert lo configuramos
    return (
        message.error({
            content: error,
            className: 'custom-class',
            style: {
                marginTop: '5vh',
            },
        }))
}

function mostrarAlertaEliminado(nombre) {
    return (
        message.success({
            content: 'Borrado exitoso',
            className: 'custom-class',
            style: {
                marginTop: '5vh',
            },
        }))
}

function mostrarMensajeFooter(error, nombre) {
    /*
     CONST: Es una constante la cual NO cambiara su valor en ningún momento en el futuro. 
     VAR: Es una variable que SI puede cambiar su valor y su scope es local. 
     LET: Es una variable que también podra cambiar su valor, pero solo vivirá(Funcionara) en el bloque donde fue declarada.*/
    let componente;
    if (error) { // Si el error es true       
        componente = <Alert message={'El ' + nombre + ' ingresado ya existe'} banner="true" type="error" /> // Cargo en la variable componente el mensaje de error con Alert de antdesign
    } else {
        componente = null; //En caso que no haya error reseteo la variable para que no muestre el error en el footer del modal
    }
    return componente;
}

function mostrarMensajeFooter1(error, nombre) {
    /*
     CONST: Es una constante la cual NO cambiara su valor en ningún momento en el futuro. 
     VAR: Es una variable que SI puede cambiar su valor y su scope es local. 
     LET: Es una variable que también podra cambiar su valor, pero solo vivirá(Funcionara) en el bloque donde fue declarada.*/
    let componente;
    if (error) { // Si el error es true       
        componente = <Alert message={nombre} banner="true" type="error" /> // Cargo en la variable componente el mensaje de error con Alert de antdesign
    } else {
        componente = null; //En caso que no haya error reseteo la variable para que no muestre el error en el footer del modal
    }
    return componente;
}

function mostrarMensajeValidacionFooter(validacion, mensaje) {
    /*
     CONST: Es una constante la cual NO cambiara su valor en ningún momento en el futuro. 
     VAR: Es una variable que SI puede cambiar su valor y su scope es local. 
     LET: Es una variable que también podra cambiar su valor, pero solo vivirá(Funcionara) en el bloque donde fue declarada.*/
    let validar;
    if (validacion) { // Si el error es true       
        validar = <Alert message={mensaje} banner="true" type="error" /> // Cargo en la variable componente el mensaje de error con Alert de antdesign
    } else {
        validar = null; //En caso que no haya error reseteo la variable para que no muestre el error en el footer del modal
    }
    return validar;
}

export {
    mostrarAlertaLogin,
    mostrarAlertaExito,
    mostrarAlertaModificar,
    mostrarAlertaError,
    mostrarAlertaEliminado,
    mostrarMensajeFooter,
    mostrarMensajeFooter1,
    mostrarExisteEnOtraTabla,
    mostrarErrorCatch,
    mostrarMensajeValidacionFooter
};