import React, { useEffect, useState } from 'react';
import axios from 'axios';   //npm install axios (instalar)
import { Row, Col, Divider } from 'antd';
import {
    UsergroupDeleteOutlined,
    RightCircleFilled,
    FileTextOutlined,
    SnippetsOutlined,
    CreditCardOutlined,
    CalendarOutlined,
} from '@ant-design/icons'; //npm i antd (instalar)
import { Link } from 'react-router-dom';
import { cerrarSesion, expiredToken } from '../Autorizacion';
import { mostrarErrorCatch } from '../Mensajes';

const style1 = { background: '#08979c', padding: '15px 20px', color: 'white' };
const style2 = { background: '#389e0d', padding: '15px 20px', color: 'white' };
const style3 = { background: '#faad14', padding: '15px 20px', color: 'black' };
const style4 = { background: '#cf1322', padding: '15px 20px', color: 'white' };
const style6 = { background: '#096dd9', padding: '15px 20px', color: 'white' };
const style7 = { background: '#722ed1', padding: '15px 20px', color: 'white' };
const style8 = { background: '#eb2f96', padding: '15px 20px', color: 'white' };
const style5 = { padding: '15px 20px' };


export default function InformacionRecepcion() {
    const [cantClientes, setCantClientes] = useState([]);
    const [cantUsuariosWeb, setCantUsuariosWeb] = useState([]);
    const [cantOrdenesPendientes, setCantOrdenesPendientes] = useState([]);
    const [totalFacturado, setTotalFacturado] = useState([])

    const peticionCantClientes = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Cliente/Cantidad', config)
                .then(response => {
                    setCantClientes(response.data.data); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    const peticionCantUsuariosWeb = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('UsuarioWeb/Cantidad', config)
                .then(response => {
                    setCantUsuariosWeb(response.data.data); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    const peticionCantOrdenesPendientes = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Venta/Cantidad', config)
                .then(response => {
                    setCantOrdenesPendientes(response.data.data); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    const peticionTotalFacturado = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Venta/Total', config)
                .then(response => {
                    setTotalFacturado(response.data.data); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    useEffect(() => {
        peticionCantClientes()
        peticionCantUsuariosWeb()
        peticionCantOrdenesPendientes()
        peticionTotalFacturado()
    }, [])

    return (
        <div>
            <Divider orientation="left"> Resumen de Ventas </Divider>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={6}>
                    <div style={style1}>
                        <UsergroupDeleteOutlined style={{ fontSize: '75px', float: 'right', color: '#8c8c8c' }} />
                        <strong style={{ fontSize: '35px' }}>{cantClientes}</strong>
                        <br></br>
                        <strong style={{ fontSize: '20px' }}>Clientes </strong>
                        <p></p>
                        <div style={{ background: '#006d75', textAlign: 'center' }}>
                            <Link to={"ListadoClientes"} style={{ color: 'white' }}>
                                Mas Info &nbsp;<RightCircleFilled />
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div style={style2}>
                        <UsergroupDeleteOutlined style={{ fontSize: '75px', float: 'right', color: '#8c8c8c' }} />
                        <strong style={{ fontSize: '35px' }}>{cantUsuariosWeb}</strong>
                        <br></br>
                        <strong style={{ fontSize: '20px' }}>Usuarios Web </strong>
                        <p></p>
                        <div style={{ background: '#237804', textAlign: 'center' }}>
                            <Link to={"/"} style={{ color: 'white' }}>
                                Mas Info &nbsp;<RightCircleFilled />
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div style={style3}>
                        <FileTextOutlined style={{ fontSize: '75px', float: 'right', color: '#bfbfbf' }} />
                        <strong style={{ fontSize: '35px' }}> {cantOrdenesPendientes}</strong>
                        <br></br>
                        <strong style={{ fontSize: '20px' }}>Ventas Pendientes </strong>
                        <p></p>
                        <div style={{ background: '#d4b106', textAlign: 'center' }}>
                            <Link to={"/"} style={{ color: 'white' }}>
                                Mas Info &nbsp;<RightCircleFilled />
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div style={style4}>
                        <CreditCardOutlined style={{ fontSize: '75px', float: 'right', color: '#bfbfbf' }} />
                        <strong style={{ fontSize: '35px' }}> $ {totalFacturado}</strong>
                        <br></br>
                        <strong style={{ fontSize: '20px' }}>Total Facturado </strong>
                        <p></p>
                        <div style={{ background: '#a8071a', textAlign: 'center' }}>
                            <Link to={"/"} style={{ color: 'white' }}>
                                Mas Info &nbsp;<RightCircleFilled />
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
            <br></br>

            <br></br>

        </div>
    )
}