import React, { Component } from 'react';
import { Drawer, Form, Button, Input, Space, Tooltip, DatePicker } from 'antd'; //npm i antd (instalar)
import { SearchOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import { mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esNumero} from '../../Paginas/Validaciones';
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class BusquedaHojaServicio extends Component {
    state = {
        visible: false,
        validacionNumeroPlertys: false,
        validacionNumeroReclamo: false,
        datos: {
            numeroReclamo: '',
            numeroPlertys: '',
            fecha: ''
        }
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    validacionForm = async () => {
        if (esNumero(this.state.datos.numeroReclamo)) {
            await this.setState({ validacionNumeroReclamo: true })
        }
        else {
            await this.setState({ validacionNumeroReclamo: false })
        }
        if (esNumero(this.state.datos.numeroPlertys)) {
            await this.setState({ validacionNumeroPlertys: true })
        }
        else {
            await this.setState({ validacionNumeroPlertys: false })
        }
    }

    onChangeFecha = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                datos: {
                    ...this.state.datos,
                    fecha: ''
                },
            })
        } else {
            await this.setState({
                datos: {
                    ...this.state.datos,
                    fecha: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
            })
        }
    }

    // en el caso de modificar no necesito resetear los campos ya que si da error necesito que los mismos queden seleccionados
    resertFormCrear = async () => {
        await this.setState({
            validacionNumeroPlertys: false,
            validacionNumeroReclamo: false
        });
    }

    botonReset = async () => {
        await this.setState({
            datos: {
                numeroReclamo: '',
                numeroPlertys: '',
                fecha: ''
            },
            validacionNumeroPlertys: false,
            validacionNumeroReclamo: false,
        });
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            datos: {
                ...this.state.datos,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.listaHojaServicio(this.state.datos.numeroReclamo, this.state.datos.numeroPlertys, this.state.datos.fecha);
        this.onClose();
    }

    render() {
        return (
            <div>
                <Tooltip title="Buscar">
                    <Button
                        type="primary"
                        shape="circle"                        
                        onClick={() => {
                            this.showDrawer();
                            this.resertFormCrear();
                        }}
                        icon={<SearchOutlined />} />
                </Tooltip>
                <Drawer
                    title="Hoja de Servicio - Buscar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Nº Reclamo">
                            <Input
                                name="numeroReclamo"
                                type="text"
                                id="numeroReclamo"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.datos && this.state.datos.numeroReclamo}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNumeroReclamo, 'El numero de reclamo solo debe contener numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Nº Plertys">
                            <Input
                                name="numeroPlertys"
                                type="text"
                                id="numeroPlertys"
                                maxLength={200}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.datos && this.state.datos.numeroPlertys}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNumeroPlertys, 'El plertys debe contener numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Fecha"
                            >
                            <DatePicker
                                style={{ width: '100%' }}
                                value={this.state.datos.fecha !== "" ? moment(this.state.datos.fecha) : null}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFecha} >
                            </DatePicker>
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                <Button
                                    type='primary'
                                    value='Reset'
                                    danger
                                    onClick={this.botonReset}>
                                    Reset
                                </Button>
                                {!this.state.validacionNumeroReclamo && !this.state.validacionNumeroPlertys ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Buscar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Buscar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        );
    }
}