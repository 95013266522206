import React, { Component } from 'react';
import { Table, Form } from 'antd'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import { MinusCircleTwoTone, CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import BusquedaReclamo from './BusquedaReclamo';
import DetalleReclamo from './DetalleReclamo';
import ModificarReclamo from './ModificarReclamo';
import BajaReclamo from './BajaReclamo';
import { Link } from 'react-router-dom';
import CrearHojaServicio from '../HojaServicio/CrearHojaServicio';
import CrearIngresoMaquina from '../IngresoMaquinas/CrearIngresoMaquina';

const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

export default class ListadoReclamos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            data: [],
            paginado: [],
            pagina: 0, // usado para cargar el numero de pagina del paginado en el handleChange el cual invoco al final en el onchange del table
            nombreCliente: '', // usado para realizar la busqueda por nombre
            numeroReclamo: '',
        }
    };

    handleChange = async value => { //Obtengo el numero de pagina dentro del paginado
        await this.setState({
            pagina: value
        });
        this.peticionGet();
    }

    pasarUrl = () => { //Esta funcion es para no escribir .then mas de una vez dentro de peticion get
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }
        if (this.state.numeroReclamo !== '' && this.state.nombreCliente === '') {
            return axios.get('Reclamo?numeroReclamo=' + this.state.numeroReclamo + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numeroReclamo === '' && this.state.nombreCliente !== '') {
            return axios.get('Reclamo?nombreCliente=' + this.state.nombreCliente + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numeroReclamo !== '' && this.state.nombreCliente !== '') {
            return axios.get('Reclamo?numeroReclamo=' + this.state.numeroReclamo + '&nombreCliente=' + this.state.nombreCliente + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numeroReclamo === '' && this.state.nombreCliente === '') {
            return axios.get('Reclamo?pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
    }

    peticionGet = async (numReclamo = '', nomCliente = '') => { //los inicializo porque sino me dice undefined
        if (numReclamo !== '' || nomCliente !== '') {
            await this.setState({
                numeroReclamo: numReclamo,
                nombreCliente: nomCliente,
                pagina: 0 //Aca si no esta en la pagina 0 lo obligo a que este para poder buscar
            });
        } else {
            await this.setState({
                numeroReclamo: '',
                nombreCliente: ''
            });
        }
        if (!expiredToken()) {
            this.pasarUrl()
                .then(response => {
                    let reclamos = response.data.data
                    reclamos.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.
                        let fechaR = moment(new Date(el.fecha));
                        return el.fecha = fechaR.format("DD-MM-YYYY");
                    })
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }
    componentDidMount() {
        this.peticionGet();
    }

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idReclamo',
                key: 'idReclamo',
                width: '7%',
                sorter: (a, b) => a.idReclamo - b.idReclamo,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Fecha ',
                dataIndex: 'fecha',
                key: 'fecha',
                width: '12%',
                sorter: (a, b) => a.fecha.localeCompare(b.fecha),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Cliente',
                dataIndex: ['unObjCliente', 'nombre'],
                key: 'nombre',
                width: '26%',
                sorter: (a, b) => a.unObjCliente.nombre.localeCompare(b.unObjCliente.nombre),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Aignado',
                dataIndex: ['unObjAsignadoReclamo', 'asignado'],
                key: 'asignado',
                width: '10%',
                sorter: (a, b) => a.unObjAsignadoReclamo.asignado.localeCompare(b.unObjAsignadoReclamo.asignado),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Estado',
                dataIndex: ['unObjEstadoReclamo', 'estado'],
                key: 'estado',
                width: '10%',
                sorter: (a, b) => a.unObjEstadoReclamo.estado.localeCompare(b.unObjEstadoReclamo.estado),
                sortDirections: ['descend', 'ascend'],
                render: fila => (fila === 'Pendiente') ? <CloseCircleTwoTone twoToneColor="#cf1322" /> : (fila === 'En Curso') ? <MinusCircleTwoTone twoToneColor="#fadb14" /> : <CheckCircleTwoTone twoToneColor="#52c41a" />
            },
            {
                title: 'Autor',
                dataIndex: ['unObjUsuario', 'nombre'],
                key: 'nombre',
                width: '10%',
                sorter: (a, b) => a.unObjUsuario.nombre.localeCompare(b.unObjUsuario.nombre),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '30%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarReclamo
                                    filaSeleccionada={fila}
                                    listaReclamos={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaReclamo
                                    filaSeleccionada={fila}
                                    listaReclamos={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetalleReclamo
                                    filaSeleccionada={fila}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            {localStorage.getItem('Usuario') === 'AreaTecnica' || localStorage.getItem('Usuario') === 'Manager'  ?
                                <label>
                                    <CrearHojaServicio
                                        filaSeleccionada={fila}
                                        listaReclamos={this.peticionGet}
                                        id={1}
                                        datos={this.state.data}
                                    />
                                </label>
                                :
                                ''
                            }
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Reclamos</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>
                    <Link to="/PaginaPrincipal/ListadoReclamosPendientes">
                        <CloseCircleTwoTone twoToneColor="#cf1322" /> Pendiente
                    </Link>
                    {tab}
                    <Link to="/PaginaPrincipal/ListadoReclamosEnCurso">
                        <MinusCircleTwoTone twoToneColor="#d4b106" /> En Curso
                    </Link>
                    {tab} <CheckCircleTwoTone twoToneColor="#52c41a" /> Finalizado
                </div>
                <div style={{ float: 'right' }}>
                    <Form layout="inline" style={{ width: 340 }}>
                        <Form.Item style={{ width: 340 }}>
                            <BusquedaReclamo
                                listaReclamos={this.peticionGet}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <br /><br /><br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ total: this.state.paginado.totalRegistros, pageSize: this.state.paginado.registrosPorPagina, onChange: this.handleChange }}
                    columns={columns}
                    dataSource={this.state.data}
                    rowKey={row => row.idReclamo} />
            </div>
        );
    }
}