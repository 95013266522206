import React from 'react';
import ReactDOM from 'react-dom';
import Rutas from './Paginas/Rutas';
import axios from 'axios';

//axios.defaults.baseURL = 'http://localhost:8083/api'; //IIS Local a mi pc
//axios.defaults.baseURL = 'http://192.168.1.143:8083/api'; // Servidor
axios.defaults.baseURL = 'https://api.cafemax.uy/api'; // Servidor dedicado

ReactDOM.render(
    <Rutas/>,
  document.getElementById('root')
);
