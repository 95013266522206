import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, DatePicker, Empty } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { Select } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)
import { mostrarAlertaError, mostrarErrorCatch, mostrarMensajeValidacionFooter, mostrarMensajeFooter1, mostrarAlertaModificar } from '../../Paginas/Mensajes';
import { comienzoOracionMayuscula, esNumero, esNombreNumeros, quitoEspacios, todasMayusculas, esDescripcion } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class ModificarHojaServicio extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar        
        error: false,
        detalleError: '',
        validacionNumHojaServicio: false,
        validacionFechaServicio: false,
        validacionProcedimientoRealizado: false,
        validacionObservaciones: false,
        validacionNumSerie: false,
        validacionNumPlertys: false,
        validacionSelectTipoServicio: false,
        validacionSelectTecnico: false,
        fechaModificada: false,
        hojaServicio: {
            idHojaServicio: '',
            numHojaServicio: '',
            fechaServicio: '',
            idTipoServicio: '',
            procedimientoRealizado: '',
            observaciones: '',
            numSerie: '',
            numPlertys: '',
            idTecnico: '',
            idReclamo: '',
        },
        cliente: '',
        maquina: '',
        tipoServicio: [],
        tecnico: []
    };

    showDrawer = () => {
        this.cargarDatos();
        this.obtenerTipoHojaServicioSelect();
        this.obtenerTecnicoSelect();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacionNumHojaServicio: false,
            validacionFechaServicio: false,
            validacionProcedimientoRealizado: false,
            validacionObservaciones: false,
            validacionNumSerie: false,
            validacionNumPlertys: false,
            validacionSelectTipoServicio: false,
            validacionSelectTecnico: false,
            fechaModificada: false,
        });
    };

    obtenerTipoHojaServicioSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de estado
                .get('TipoServicioHojaServicio', config)
                .then(response => {
                    this.setState({ tipoServicio: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    obtenerTecnicoSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de estado
                .get('Tecnico/Activos', config)
                .then(response => {
                    this.setState({ tecnico: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    cargarDatos = async () => {
        await this.setState({
            hojaServicio: {
                idHojaServicio: this.props.filaSeleccionada.idHojaServicio,
                numHojaServicio: this.props.filaSeleccionada.numHojaServicio,
                fechaServicio: this.props.filaSeleccionada.fechaServicio,
                idTipoServicio: this.props.filaSeleccionada.idTipoServicio,
                procedimientoRealizado: this.props.filaSeleccionada.procedimientoRealizado,
                observaciones: this.props.filaSeleccionada.observaciones,
                numSerie: this.props.filaSeleccionada.numSerie,
                numPlertys: this.props.filaSeleccionada.numPlertys,
                idTecnico: this.props.filaSeleccionada.idTecnico,
                idReclamo: this.props.filaSeleccionada.idReclamo,
            },
            cliente: this.props.filaSeleccionada.unObjreclamo.unObjCliente.nombre,
            maquina: this.props.filaSeleccionada.unObjreclamo.unObjMaquina.modelo,
        });        
        this.obtenerTipoHojaServicioSelect();
        this.obtenerTecnicoSelect();
    }

    validacionForm = async () => {
        if (esNumero(this.state.hojaServicio.numHojaServicio)) {
            await this.setState({ validacionNumHojaServicio: true })
        }
        else {
            await this.setState({ validacionNumHojaServicio: false })
        }
        if (!esDescripcion(this.state.hojaServicio.procedimientoRealizado)) {
            await this.setState({ validacionProcedimientoRealizado: false })
        }
        else {
            await this.setState({ validacionProcedimientoRealizado: true })
        }
        if (!esDescripcion(this.state.hojaServicio.observaciones)) {
            await this.setState({ validacionObservaciones: false })
        }
        else {
            await this.setState({ validacionObservaciones: true })
        }
        if (!esNombreNumeros(this.state.hojaServicio.numSerie)) {
            await this.setState({ validacionNumSerie: false })
        }
        else {
            await this.setState({ validacionNumSerie: true })
        }
        if (!esNombreNumeros(this.state.hojaServicio.numPlertys)) {
            await this.setState({ validacionNumPlertys: false })
        }
        else {
            await this.setState({ validacionNumPlertys: true })
        }
    }

    validacionSelectTipoServicioHojaServicio = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.hojaServicio.idTipoServicio === '0' || this.state.hojaServicio.idTipoServicio === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionSelectTecnico = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.hojaServicio.idTecnico === '0' || this.state.hojaServicio.idTecnico === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionFecha = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.hojaServicio.fechaServicio === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    onChangeFecha = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                hojaServicio: {
                    ...this.state.hojaServicio,
                    fechaServicio: ''
                },
                validacionFechaServicio: true, //al colocar esto se muestra el msj de La fecha no puede ser vacia
                fechaModificada: false
            })
        } else {
            await this.setState({
                hojaServicio: {
                    ...this.state.hojaServicio,
                    fechaServicio: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionFechaServicio: false, //al colocar esto se borra el msj de La fecha no puede ser vacia
                fechaModificada: true
            })
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            hojaServicio: {
                ...this.state.hojaServicio,
                [name]: value
            }
        });
        this.validacionForm();
    }


    handleSubmit = e => { // es llamado al presionar el boton Insertar en el form
        e.preventDefault();
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    conversiones = () => {
      //  this.state.hojaServicio.numHojaServicio = quitoEspacios(this.state.hojaServicio.numHojaServicio);
        this.state.hojaServicio.numHojaServicio = Number(this.state.hojaServicio.numHojaServicio);
        this.state.hojaServicio.procedimientoRealizado = quitoEspacios(this.state.hojaServicio.procedimientoRealizado);
        this.state.hojaServicio.procedimientoRealizado = comienzoOracionMayuscula(this.state.hojaServicio.procedimientoRealizado);
        this.state.hojaServicio.observaciones = quitoEspacios(this.state.hojaServicio.observaciones);
        this.state.hojaServicio.observaciones = comienzoOracionMayuscula(this.state.hojaServicio.observaciones);
        this.state.hojaServicio.numSerie = quitoEspacios(this.state.hojaServicio.numSerie);
        this.state.hojaServicio.numSerie = todasMayusculas(this.state.hojaServicio.numSerie);
        this.state.hojaServicio.numPlertys = quitoEspacios(this.state.hojaServicio.numPlertys);
        this.state.hojaServicio.numPlertys = todasMayusculas(this.state.hojaServicio.numPlertys);
    }

    reverseFecha = async () => {
        if (this.state.fechaModificada === false) {
            let fecham = this.state.hojaServicio.fechaServicio.split('-').reverse().join('-')
            await this.setState({ // Cago el la fecha invertida 
                hojaServicio: {
                    ...this.state.hojaServicio,
                    fechaServicio: fecham,
                }
            });
        }

        return this.state.hojaServicio.fechaServicio
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }

        if (!expiredToken()) {    
            this.conversiones();                  
            await this.setState({ validacionFechaServicio: this.validacionFecha() }) // Devuleve true si no ingrese fecha, false en caso contrario 
            await this.setState({ validacionSelectTipoServicio: this.validacionSelectTipoServicioHojaServicio() });
            await this.setState({ validacionSelectTecnico: this.validacionSelectTecnico() });
            if (this.state.validacionFechaServicio === false) { // Si no selecciona la fecha de inicio muestro el mensaje de error y no entro al post 
                if (this.state.validacionSelectTipoServicio === false) {
                    if (this.state.validacionSelectTecnico === false) {                        
                        this.reverseFecha();       
                        await axios.put('HojaServicio', this.state.hojaServicio, config) // Inserta en la Base de datos el nuevo registro                            
                            .then(response => {                               
                                this.props.listaHojasServicio(); // obtengo la lista de clientes pasada por props desde ListadoClientes.js (Al clrear el trabajo vuelve a mostrarme la lista de clientes)
                                this.onClose(); // Cierra el modal luego del ingreso  
                                mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearPortfolio.js
                            }).catch(error => {
                                if (error.response) {
                                    if (error.response.data.errors.fecha) { // al tener la fecha un array de errores selecciono el que me interesa desde la api
                                        mostrarAlertaError(error.response.data.errors.Fecha[0])
                                        this.setState({
                                            error: true,
                                            detalleError: error.response.data.errors.Fecha[0]
                                        })
                                    }
                                    else {
                                        mostrarAlertaError(error.response.data.errors[0].detail)
                                        this.setState({
                                            error: true,
                                            detalleError: error.response.data.errors[0].detail
                                        })
                                    }
                                }
                            }).catch(errorServer => {
                                errorServer = mostrarErrorCatch();
                            })
                    }
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {
        return (
            <div style={{ float: "left" }}>
                <EditTwoTone
                    twoToneColor="#d48806"
                    style={{ fontSize: '15px' }}
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ error: false });
                    }} />


                <Drawer
                    title="Hoja de Servicio - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter1(this.state.error, this.state.detalleError)}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                    <Form.Item
                            label="Numero"
                            hidden
                        >
                            <Input
                                disabled
                                hidden
                                name="idHojaServicio"
                                type="text"
                                id="idHojaServicio"
                                size="small"
                                value={this.state.hojaServicio && this.state.hojaServicio.idHojaServicio}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Nº Servicio"
                            required
                        >
                            <Input
                                name="numHojaServicio"
                                type="text"
                                id="numHojaServicio"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.hojaServicio && this.state.hojaServicio.numHojaServicio}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNumHojaServicio, 'El numero de servicio solo debe contener numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Reclamo"
                        >
                            <Input
                                name="idReclamo"
                                type="text"
                                disabled
                                id="idReclamo"
                                size="small"
                                value={this.state.hojaServicio && this.state.hojaServicio.idReclamo}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Cliente"
                        >
                            <Input
                                name="cliente"
                                type="text"
                                disabled
                                id="cliente"
                                size="small"
                                value={this.state && this.state.cliente}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Maquina"
                        >
                            <Input
                                name="maquina"
                                type="text"
                                disabled
                                id="maquina"
                                size="small"
                                value={this.state && this.state.maquina}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Fecha"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={moment(this.state.hojaServicio.fechaServicio, dateFormat)}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFecha} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionFechaServicio, 'Debe seleccionar una fecha')}
                        </Form.Item>
                        <Form.Item
                            label="Tipo Servcio"
                            required
                        >
                            <Select
                                name="idTipoServicio"
                                id="idTipoServicio"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        hojaServicio: {
                                            ...this.state.hojaServicio,
                                            idTipoServicio: selectedGroup
                                        },
                                        validacionSelectTipoServicio: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.hojaServicio && this.state.hojaServicio.idTipoServicio) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Tipo de servicio </label></Option>
                                {this.state.tipoServicio.map(elemento => (
                                    <Option key={elemento.idTipoServicio} value={elemento.idTipoServicio}>{elemento.tipoServicio}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionSelectTipoServicio, 'Debe seleccionar un tipo de servicio')}
                        </Form.Item>
                        <Form.Item
                            label="Procedimiento Realizado"
                            required>
                            <TextArea
                                name="procedimientoRealizado"
                                type="text"
                                rows={4}
                                id="procedimientoRealizado"
                                minLength={6}
                                maxLength={500}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.hojaServicio && this.state.hojaServicio.procedimientoRealizado}
                                required
                            >
                            </TextArea>
                            {mostrarMensajeValidacionFooter(this.state.validacionProcedimientoRealizado, 'El procedimiento realizado solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Observaciones"
                        >
                            <TextArea
                                name="observaciones"
                                type="text"
                                rows={4}
                                id="observaciones"
                                minLength={6}
                                maxLength={500}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.hojaServicio && this.state.hojaServicio.observaciones}

                            >
                            </TextArea>
                            {mostrarMensajeValidacionFooter(this.state.validacionObservaciones, 'Las observaciones solo deben contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Nº Serie"
                        >
                            <Input
                                name="numSerie"
                                type="text"
                                id="numSerie"
                                minLength={2}
                                maxLength={20}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.hojaServicio && this.state.hojaServicio.numSerie}

                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNumSerie, 'El serie solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Nº Plertys"
                            required>
                            <Input
                                name="numPlertys"
                                type="text"
                                id="numPlertys"
                                minLength={2}
                                maxLength={20}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.hojaServicio && this.state.hojaServicio.numPlertys}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNumPlertys, 'El plertys solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Tecnico"
                            required
                        >
                            <Select
                                name="idTecnico"
                                id="idTecnico"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        hojaServicio: {
                                            ...this.state.hojaServicio,
                                            idTecnico: selectedGroup
                                        },
                                        validacionSelectTecnico: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.hojaServicio && this.state.hojaServicio.idTecnico) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Tecnico </label></Option>
                                {this.state.tecnico.map(elemento => (
                                    <Option key={elemento.idTecnico} value={elemento.idTecnico}>{elemento.nombre}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionSelectTecnico, 'Debe seleccionar un tecnico')}
                        </Form.Item>                       
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacionNumHojaServicio && !this.state.validacionNumPlertys && !this.state.validacionNumSerie &&
                                    !this.state.validacionObservaciones && !this.state.validacionProcedimientoRealizado ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Actualizar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Actualizar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}