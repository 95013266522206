import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom' //npm install react-router-dom (instalar)
import PaginaPrincipal from './PaginaPrincipal';
import Login from './Login';

function Rutas() {
    return ( //estas son las rutas para el inicio de la aplicacion, se importan en index.js
        <BrowserRouter>
            <Routes>                
                <Route exact path="/*" element={<Login/>} /> 
                <Route exact path="/PaginaPrincipal/*" element={<PaginaPrincipal/>} /> 
            </Routes>
        </BrowserRouter>
    )
}

export default Rutas;