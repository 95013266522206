import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, Image, Checkbox } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { Select } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaModificar, mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esNombreNumeros, esNumero, esNumeroPunto, primeraMayuscula, quitoEspacios } from '../../Paginas/Validaciones';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class ModificarProducto extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar      
        error: false,
        validacion: false,
        validacionPrecio: false,
        validacionS: false,
        imagenes: null,
        descripcion: null,
        producto: {
            idProducto: '',
            titulo: '',
            precio: '',
            imagen: '',
            descripcion: '',
            idTipoProducto: '',
            activo: ''
        },
        tipoProducto: [],
    };

    showDrawer = () => {
        this.cargarDatos();
        this.obtenerTipoProductoSelect();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacionS: false, // desaparece el cartel de validacion al cancelar (Select)            
        });
    };

    cargarDatos = async (e) => {
        await this.setState({
            producto: {
                idProducto: this.props.filaSeleccionada.idProducto,
                titulo: this.props.filaSeleccionada.titulo,
                imagen: this.props.filaSeleccionada.imagen,
                descripcion: this.props.filaSeleccionada.descripcion,
                precio: this.props.filaSeleccionada.precio,
                idTipoProducto: this.props.filaSeleccionada.idTipoProducto,
                activo: this.props.filaSeleccionada.activo
            },
            imagenes: null,
            descripcion: null
        });
    }

    validacionForm = async () => {
        if (!esNombreNumeros(this.state.producto.titulo)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
        if (!esNumeroPunto(this.state.producto.precio)) {
            await this.setState({ validacionPrecio: false })
        }
        else {
            await this.setState({ validacionPrecio: true })
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            producto: {
                ...this.state.producto,
                [name]: value
            }
        });
        this.validacionForm();
    }

    checkActivoChange = async (e) => {
        var check = e.target.checked;
        await this.setState({
            producto: {
                ...this.state.producto,
                activo: check
            }
        })
    }

    obtenerTipoProductoSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de roles
                .get('TipoProducto', config)
                .then(response => {
                    this.setState({ tipoProducto: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    validacionSelect = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.producto.idTipoProducto === '0' || this.state.producto.idTipoProducto === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    handleFileChange = e => {
        this.setState({
            [e.target.name]: e.target.files[0],
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            this.state.producto.titulo = quitoEspacios(this.state.producto.titulo);
            this.state.producto.titulo = primeraMayuscula(this.state.producto.titulo);
            if (this.state.producto.titulo !== '') {// verifico que el campo rol.Tiporol no sea vacio 
                await this.setState({ validacionS: this.validacionSelect() });
                if (this.state.validacionS === false) { // Si no selecciona el portfolio muestro el mensaje de error y no entro al post
                    const formData = new FormData();
                    if (this.state.imagenes != null) {
                        if (this.state.imagenes.name.includes("imagen")) {
                            formData.append('imagenes', this.state.imagenes);
                        }
                        else {
                            formData.append('imagenes', this.state.imagenes, 'imagen' + this.state.imagenes.name);
                        }
                    }
                    if (this.state.descripcion != null) {
                        if (this.state.descripcion.name.includes("descripcion")) {
                            formData.append('imagenes', this.state.descripcion);
                        }
                        else {
                            formData.append('imagenes', this.state.descripcion, 'descripcion' + this.state.descripcion.name);
                        }
                    }
                    await axios.put('Producto?idProducto=' + this.state.producto.idProducto + '&idTipoProducto=' + this.state.producto.idTipoProducto + '&titulo=' + this.state.producto.titulo + '&precio=' + this.state.producto.precio + '&activo=' + this.state.producto.activo, formData, config)
                        .then(response => {
                            this.props.listaProductos(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABrol>
                            this.onClose(); // Cierra el modal luego del ingreso  
                            mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearRol.js
                        }).catch(error => {
                            if (error.response) {
                                mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                                this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                            }
                        }).catch(errorServer => {
                            errorServer = mostrarErrorCatch();
                        })
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <EditTwoTone
                    twoToneColor="#d48806"
                    style={{ fontSize: '15px' }}
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ error: false });
                    }} />

                <Drawer
                    title="Producto - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Producto')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                disabled
                                name="idProducto"
                                type="text"
                                id="idProducto"
                                size="small"
                                value={this.state.producto && this.state.producto.idProducto}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Titulo"
                            required>
                            <Input
                                name="titulo"
                                type="text"
                                id="titulo"
                                maxLength={16}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.producto && this.state.producto.titulo}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El titulo solo debe contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Precio"
                            required>
                            <Input
                                name="precio"
                                type="text"
                                id="precio"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.producto && this.state.producto.precio}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionPrecio, 'El precio solo debe contener numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Imagen"
                            style={{color:'white'}}                            
                            >
                            {this.state.producto.idTipoProducto === 1 ?
                                <Image
                                    src={'../Imagenes/Capsulas/' + this.state.producto.imagen}
                                    width='100px'
                                    preview={false} />
                                :
                                this.state.producto.idTipoProducto === 2 ?
                                    <Image
                                        src={'../Imagenes/Grano/' + this.state.producto.imagen}
                                        width='100px'
                                        preview={false} />
                                    :
                                    <Image
                                        src={'../Imagenes/Molido/' + this.state.producto.imagen}
                                        width='100px'
                                        preview={false} />
                            }
                        </Form.Item>
                        <Form.Item
                            label="Imagen">
                            <Input
                                style={{ border: 0 }}
                                name="imagenes"
                                id="imagenes"
                                type="file"
                                size="small"
                                accept=".jpeg, .png, .jpg"
                                onChange={this.handleFileChange}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Descripcion"
                            style={{color:'white'}}  >
                            {this.state.producto.idTipoProducto === 1 ?
                                <Image
                                    src={'../Imagenes/Capsulas/' + this.state.producto.descripcion}
                                   // width='100px'
                                    preview={false} />
                                :
                                this.state.producto.idTipoProducto === 2 ?
                                    <Image
                                        src={'../Imagenes/Grano/' + this.state.producto.descripcion}
                                      //  width='100px'
                                        preview={false} />
                                    :
                                    <Image
                                        src={'../Imagenes/Molido/' + this.state.producto.descripcion}
                                      //  width='100px'
                                        preview={false} />
                            }
                        </Form.Item>
                        <Form.Item
                            label="Descripcion"
                            required>
                            <Input
                                style={{ border: 0 }}
                                name="descripcion"
                                type="file"
                                size="small"
                                accept=".jpeg, .png, .jpg"
                                onChange={this.handleFileChange}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Tipo"
                            required
                        >
                            <Select
                                name="idTipoProducto"
                                id="idTipoProducto"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        producto: {
                                            ...this.state.producto,
                                            idTipoProducto: selectedGroup
                                        },
                                        validacionS: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.producto && this.state.producto.idTipoProducto) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Seleccionar tipo </label></Option>
                                {this.state.tipoProducto.map(elemento => (
                                    <Option key={elemento.idTipoProducto} value={elemento.idTipoProducto}>{elemento.descripcion}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionS, 'Debe seleccionar un tipo')}
                        </Form.Item>
                        <Form.Item
                            label="Activo"
                        >
                            <Checkbox
                                name="activo"
                                id="activo"
                                size='small'
                                onChange={this.checkActivoChange}
                                checked={this.state.producto.activo}
                            >
                            </Checkbox>
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Modificar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Modificar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}