import React, { Component } from 'react';
import { Table, Form } from 'antd'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import CrearCliente from './CrearCliente';
import BajaCliente from './BajaCliente';
import ModificarCliente from './ModificarCliente';
import DetalleCliente from './DetalleCliente';
/*
import CrearTrabajo from '../Trabajo/CrearTrabajo';*/
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import BusquedaClientes from './BusquedaClientes';
import CrearReclamo from '../Reclamo/CrearReclamo';

export default class ListadoClientes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            data: [],
            paginado: [],
            pagina: 0, // usado para cargar el numero de pagina del paginado en el handleChange el cual invoco al final en el onchange del table
            nombre: '', // usado para realizar la busqueda por nombre
            rut: '',
        }
    };

    handleChange = async value => { //Obtengo el numero de pagina dentro del paginado
        await this.setState({
            pagina: value
        });
        this.peticionGet();
    }

    pasarUrl = () => { //Esta funcion es para no escribir .then mas de una vez dentro de peticion get
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }
        if (this.state.rut !== '' && this.state.nombre === '') {
            return axios.get('Cliente?rut=' + this.state.rut + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.rut === '' && this.state.nombre !== '') {
            return axios.get('Cliente?nombre=' + this.state.nombre + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.rut !== '' && this.state.nombre !== '') {
            return axios.get('Cliente?rut=' + this.state.rut + '&nombre=' + this.state.nombre + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.rut === '' && this.state.nombre === '') {
            return axios.get('Cliente?pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
    }

    peticionGet = async (Rut = '', Nombre = '') => { //los inicializo porque sino me dice undefined
        if (Rut !== '' || Nombre !== '') {
            await this.setState({
                rut: Rut,
                nombre: Nombre,
                pagina: 0 //Aca si no esta en la pagina 0 lo obligo a que este para poder buscar
            });
        } else {
            await this.setState({
                rut: '',
                nombre: ''
            });
        }
        if (!expiredToken()) {
            this.pasarUrl()
                .then(response => {
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }
    componentDidMount() {
        this.peticionGet();
    }

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idCliente',
                key: 'idCliente',
                width: '7%',
                sorter: (a, b) => a.idCliente - b.idCliente,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Rut',
                dataIndex: 'rut',
                key: 'rut',
                width: '12%',
                sorter: (a, b) => a.rut.localeCompare(b.rut),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
                width: '35%',
                sorter: (a, b) => a.nombre.localeCompare(b.nombre),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Telefono',
                dataIndex: 'telefono',
                key: 'telefono',
                width: '15%',
                sorter: (a, b) => a.telefono.localeCompare(b.telefono),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '30%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarCliente
                                    filaSeleccionada={fila}
                                    listaClientes={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaCliente
                                    filaSeleccionada={fila}
                                    listaClientes={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetalleCliente
                                    filaSeleccionada={fila}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <CrearReclamo
                                    filaSeleccionada={fila}
                                    listaClientes = {this.peticionGet}
                                    datos={this.state.data}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Clientes</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>
                <CrearCliente
                    listaClientes={this.peticionGet}
                    datos={this.state.data} />
                </div>
                <div style={{ float: 'right' }}>
                    <Form layout="inline" style={{ width: 340 }}>
                        <Form.Item style={{ width: 340 }}>
                            <BusquedaClientes
                                listaClientes={this.peticionGet}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <br /><br /><br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ total: this.state.paginado.totalRegistros, pageSize: this.state.paginado.registrosPorPagina, onChange: this.handleChange }}
                    columns={columns.filter(col => col.dataIndex !== 'idCliente')}
                    dataSource={this.state.data}
                    rowKey={row => row.idCliente} />
            </div>
        );
    }
}