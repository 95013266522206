import React, { Component } from 'react'
import '../../Estilos/estilos.css';
import { Descriptions, Image, Drawer } from 'antd'; //npm i antd (instalar)
import { InfoCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

export default class DetalleIngresoMaquina extends Component {
    state = {
        visible: false,
    }

    showDrawer = () => {       
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,            
        });
    };  

    render() {

        return (
            <div style={{ float: "left" }}>                
                <InfoCircleTwoTone
                    twoToneColor="#7cb305"
                    style={{ fontSize: '15px' }}
                    onClick={() => {
                        this.showDrawer();                       
                    }} />                   
                <Drawer
                    title="Ingreso de Maquina - Ficha"
                    width={500}                    
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                   
                >
                    <Descriptions size="default" column={1} contentStyle={{ color: "slategray" }}>
                    <Descriptions.Item label="Número" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.idIngresoMaquina} </Descriptions.Item>
                    <Descriptions.Item label="Fecha Ingreso" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.fechaIngreso}</Descriptions.Item>                 
                    <Descriptions.Item label="Motivo" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.unObjMotivoIngreso.motivo)}</Descriptions.Item>                    
                    <Descriptions.Item label= "Maquina" labelStyle={{ fontWeight: 600 }}>
                        <Image
                            width={200}
                            src={'../Imagenes/' + this.props.filaSeleccionada.unObjMaquina.imagen}
                            preview={false}>
                        </Image>
                    </Descriptions.Item>
                    <Descriptions.Item label="Modelo" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjMaquina.modelo}</Descriptions.Item>
                    <Descriptions.Item label="Nº Plertys" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.numPlertys)}</Descriptions.Item>
                    <Descriptions.Item label="Nº Serie" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.numSerie)}</Descriptions.Item>
                    <Descriptions.Item label="Descripcion Falla" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.descripcionFalla)}</Descriptions.Item>
                    <Descriptions.Item label="Observaciones" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.observaciones)}</Descriptions.Item>
                    <Descriptions.Item label="Tecnico" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.unObjTecnico.nombre)}</Descriptions.Item>
                    <Descriptions.Item label="Fecha Reparacion" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.fechaReparacion)}</Descriptions.Item>
                    <Descriptions.Item label="Trabajos Realizados" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.trabajosRealizados)}</Descriptions.Item>                    
                    <Descriptions.Item label="Estado" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.unObjEstadoIngresoMaquina.estado)}</Descriptions.Item>

                </Descriptions>  
                </Drawer>
            </div >
           
        )
    }
}