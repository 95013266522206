import React, { Component } from 'react'
import '../../Estilos/estilos.css';
import { Descriptions, notification } from 'antd'; //npm i antd (instalar)
import { InfoCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

export default class DetalleTecnico extends Component {   

    openNotification = () => {
        const args = {
            description:
                <Descriptions size="small" column={1} title="Rol - Ficha" contentStyle={{ color: "slategray" }}>

                    <Descriptions.Item label="Número" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.idTecnico} </Descriptions.Item>
                    <Descriptions.Item label="Nombre" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.nombre}</Descriptions.Item>
                    <Descriptions.Item label="Activo" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.activo === false ? <CloseCircleTwoTone twoToneColor="#cf1322" /> : <CheckCircleTwoTone twoToneColor="#52c41a" />}</Descriptions.Item>          

                </Descriptions>,
            duration: 0,
        };
        return (
            notification.open(args)
        );
    }

    render() {        

        return (
            <div>
                <InfoCircleTwoTone
                    twoToneColor="#7cb305"
                    style = {{fontSize:'15px'}}                    
                    onClick={() => this.openNotification()} />  
            </div>
        )
    }
}