import React, { Component } from 'react';
import { Drawer, Form, Button, Input, Space } from 'antd'; //npm i antd (instalar)
import { PlusOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaExito, mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esNombreGuion, primeraMayuscula, quitoEspacios } from '../../Paginas/Validaciones';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class CrearTecnico extends Component {
    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        i: 0,
        ultimoTecnico: 0,
        validacion: false,
        error: false,
        tecnico: {
            idTecnico: '',
            nombre: ''
        }
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    obtenerDatos = () => {
        return (
            this.props.datos
        )
    }

    validacionForm = async () => {
        if (esNombreGuion(this.state.tecnico.nombre)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    // en el caso de modificar no necesito resetear los campos ya que si da error necesito que los mismos queden seleccionados
    resertFormCrear = async () => {
        const arreglo = this.obtenerDatos();
        const largo = this.props.datos.length;
        await this.setState({ i: largo - 1 }); // Obtengo la posicion del ultimo dato del arreglo
        if (this.state.i >= 0) {
            await this.setState({ ultimoTecnico: arreglo[this.state.i].idTecnico + 1 }); // obtengo el ultimo IdRol y le sumo para colocarlo en el value del Modal
        }
        else {
            this.setState({ ultimoTecnico: 1 })
        }         
        await this.setState({
            tecnico: {
                idTecnico: this.state.ultimoTecnico,
                nombre: ''
            }
        });
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            tecnico: {
                ...this.state.tecnico,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => {
        e.preventDefault(); // El preventDefault() método cancela el evento si es cancelable, 
        //lo que significa que la acción predeterminada que pertenece al evento no ocurrirá. 
        //Por ejemplo, esto puede ser útil cuando: Al hacer clic en un "Submit" botón, evitar que enviar un formulario. 
        if (this.state.tipoModal === 'insertar') {
            this.peticionPost();
        }
    }

    peticionPost = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }}
        if (!expiredToken()) {
            this.state.tecnico.nombre = quitoEspacios(this.state.tecnico.nombre);
            this.state.tecnico.nombre = primeraMayuscula(this.state.tecnico.nombre);
            if (this.state.tecnico.nombre !== '') {// verifico que el campo rol.Tiporol no sea vacio            
                delete this.state.tecnico.idTecnico; //Borro el Id ya que la bd lo creo automaticamente
                await axios.post('Tecnico', this.state.tecnico, config) // Inserta en la Base de datos el nuevo registro
                    .then(response => {
                        this.props.listaTecnicos(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABrol>
                        this.onClose(); // Cierra el modal luego del ingreso  
                        mostrarAlertaExito(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearRol.js
                    }).catch(error => {
                        if (error.response) {
                            mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                            this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                        }
                    }).catch(errorServer => {
                        errorServer = mostrarErrorCatch();
                    })
            }
        }
        else {
           cerrarSesion();
        }
    }

    render() {
        return (
            <div>
                <Button type="primary" size='small'
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ tipoModal: 'insertar' });
                        this.resertFormCrear();
                        this.setState({ error: false });
                    }}
                >
                    <PlusOutlined /> Nuevo Tecnico
                </Button>

                <Drawer
                    title="Tecnico - Nuevo"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Rol')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Número">
                            <Input
                                disabled
                                name="idTecnico"
                                type="text"
                                id="idTecnico"
                                size="small"
                                value={this.state.tecnico && this.state.tecnico.idTecnico}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Nombre"
                            required>
                            <Input
                                name="nombre"
                                type="text"
                                id="nombre"
                                minLength={6}
                                maxLength={50}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.tecnico && this.state.tecnico.nombre}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El tecnico solo debe contener letras o guiones')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Insertar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Insertar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        );
    }
}