import React, { Component } from 'react';
import { Drawer, Form, Button, Input, Space, Tooltip } from 'antd'; //npm i antd (instalar)
import { SearchOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import { mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esNombreNumeros, esNumero, primeraMayuscula, quitoEspacios } from '../../Paginas/Validaciones';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class BusquedaReclamo extends Component {
    state = {
        visible: false,
        validacionNombreCliente: false,
        validacionNumeroReclamo: false,
        datos: {
            numeroReclamo: '',
            nombreCliente: ''
        }
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    validacionForm = async () => {
        if (esNumero(this.state.datos.numeroReclamo)) {
            await this.setState({ validacionNumeroReclamo: true })
        }
        else {
            await this.setState({ validacionNumeroReclamo: false })
        }
        if (!esNombreNumeros(this.state.datos.nombreCliente)) {
            await this.setState({ validacionNombreCliente: false })
        }
        else {
            await this.setState({ validacionNombreCliente: true })
        }
    }

    // en el caso de modificar no necesito resetear los campos ya que si da error necesito que los mismos queden seleccionados
    resertFormCrear = async () => {
        await this.setState({
            validacionNombreCliente: false,
            validacionNumeroReclamo: false
        });
    }

    botonReset = async () => {
        await this.setState({
            datos: {
                numeroReclamo: '',
                nombreCliente: ''
            },
            validacionNombreCliente: false,
            validacionNumeroReclamo: false
        });
    }

    conversiones = () => {
        this.state.datos.nombreCliente = quitoEspacios(this.state.datos.nombreCliente);
        this.state.datos.nombreCliente = primeraMayuscula(this.state.datos.nombreCliente);
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            datos: {
                ...this.state.datos,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.conversiones();
        this.props.listaReclamos(this.state.datos.numeroReclamo, this.state.datos.nombreCliente);
        this.onClose();
    }

    render() {
        return (
            <div>
                <Tooltip title="Buscar">
                    <Button
                        type="primary"
                        shape="circle"                        
                        onClick={() => {
                            this.showDrawer();
                            this.resertFormCrear();
                        }}
                        icon={<SearchOutlined />} />
                </Tooltip>
                <Drawer
                    title="Reclamo - Buscar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                name="numeroReclamo"
                                type="text"
                                id="numeroReclamo"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.datos && this.state.datos.numeroReclamo}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNumeroReclamo, 'El numero de reclamo solo debe contener numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Cliente">
                            <Input
                                name="nombreCliente"
                                type="text"
                                id="nombreCliente"
                                maxLength={200}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.datos && this.state.datos.nombreCliente}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNombre, 'El nombre del cliente solo debe contener letras')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                <Button
                                    type='primary'
                                    value='Reset'
                                    danger
                                    onClick={this.botonReset}>
                                    Reset
                                </Button>
                                {!this.state.validacionNumeroReclamo && !this.state.validacionNombreCliente ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Buscar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Buscar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        );
    }
}