import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto'
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import { Bar } from 'react-chartjs-2'; //npm install --save react-chartjs-2 chart.js (instalar)
import axios from 'axios';   //npm install axios (instalar)
import { Col, Divider, Row } from 'antd';

export default function ReparacionesPorTecnicoPorMes() {
    const [cantidadAmaral, setCantidadAmaral] = useState([]);
    const [mesAmaral, setMesAmaral] = useState([]);
    const [cantidadRau, setCantidadRau] = useState([]);
    const [mesRau, setMesRau] = useState([]);
    const [cantidadToth, setCantidadToth] = useState([]);
    const [mesToth, setMesToth] = useState([]);
    const [cantidadMartinez, setCantidadMartinez] = useState([]);
    const [mesMartinez, setMesMartinez] = useState([]);
    const [cantidadDaveri, setCantidadDaveri] = useState([]);
    const [mesDaveri, setMesDaveri] = useState([]);
    const [cantidadSoria, setCantidadSoria] = useState([]);
    const [mesSoria, setMesSoria] = useState([]);

    const peticionAmaral = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }

        await axios.get('IngresoMaquina/PorMes' + 3, config)
            .then(response => {
                let ingresoMaquinas = response.data.data
                ingresoMaquinas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                    let date = moment(new Date(el.fecha))
                    return el.fecha = date.format("MM-YYYY");
                })
                var respuesta = response.data.data;
                var auxCantidad = [], auxMes = [];
                respuesta.map(elemento => ((
                    auxCantidad.push(elemento.cantidad),
                    auxMes.push(elemento.fecha)
                )));
                setCantidadAmaral(auxCantidad)
                setMesAmaral(auxMes)
                // Cargo en data los resultados de la consulta               
            })
    }

    const peticionRau = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }

        await axios.get('IngresoMaquina/PorMes' + 5, config)
            .then(response => {
                let ingresoMaquinas = response.data.data
                ingresoMaquinas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                    let date = moment(new Date(el.fecha))
                    return el.fecha = date.format("MM-YYYY");
                })
                var respuesta = response.data.data;
                var auxCantidad = [], auxMes = [];
                respuesta.map(elemento => ((
                    auxCantidad.push(elemento.cantidad),
                    auxMes.push(elemento.fecha)
                )));
                setCantidadRau(auxCantidad)
                setMesRau(auxMes)
                // Cargo en data los resultados de la consulta               
            })
    }

    const peticionToth = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }

        await axios.get('IngresoMaquina/PorMes' + 19, config)
            .then(response => {
                let ingresoMaquinas = response.data.data
                ingresoMaquinas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                    let date = moment(new Date(el.fecha))
                    return el.fecha = date.format("MM-YYYY");
                })
                var respuesta = response.data.data;
                var auxCantidad = [], auxMes = [];
                respuesta.map(elemento => ((
                    auxCantidad.push(elemento.cantidad),
                    auxMes.push(elemento.fecha)
                )));
                setCantidadToth(auxCantidad)
                setMesToth(auxMes)
                // Cargo en data los resultados de la consulta               
            })
    }

    const peticionMartinez = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }

        await axios.get('IngresoMaquina/PorMes' + 20, config)
            .then(response => {
                let ingresoMaquinas = response.data.data
                ingresoMaquinas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                    let date = moment(new Date(el.fecha))
                    return el.fecha = date.format("MM-YYYY");
                })
                var respuesta = response.data.data;
                var auxCantidad = [], auxMes = [];
                respuesta.map(elemento => ((
                    auxCantidad.push(elemento.cantidad),
                    auxMes.push(elemento.fecha)
                )));
                setCantidadMartinez(auxCantidad)
                setMesMartinez(auxMes)
                // Cargo en data los resultados de la consulta               
            })
    }

    const peticionDaveri = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }

        await axios.get('IngresoMaquina/PorMes' + 21, config)
            .then(response => {
                let ingresoMaquinas = response.data.data
                ingresoMaquinas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                    let date = moment(new Date(el.fecha))
                    return el.fecha = date.format("MM-YYYY");
                })
                var respuesta = response.data.data;
                var auxCantidad = [], auxMes = [];
                respuesta.map(elemento => ((
                    auxCantidad.push(elemento.cantidad),
                    auxMes.push(elemento.fecha)
                )));
                setCantidadDaveri(auxCantidad)
                setMesDaveri(auxMes)
                // Cargo en data los resultados de la consulta               
            })
    }

    const peticionSoria = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }

        await axios.get('IngresoMaquina/PorMes' + 29, config)
            .then(response => {
                let ingresoMaquinas = response.data.data
                ingresoMaquinas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                    let date = moment(new Date(el.fecha))
                    return el.fecha = date.format("MM-YYYY");
                })
                var respuesta = response.data.data;
                var auxCantidad = [], auxMes = [];
                respuesta.map(elemento => ((
                    auxCantidad.push(elemento.cantidad),
                    auxMes.push(elemento.fecha)
                )));
                setCantidadSoria(auxCantidad)
                setMesSoria(auxMes)
                // Cargo en data los resultados de la consulta               
            })
    }


    useEffect(() => {
        peticionAmaral()
        peticionRau()
        peticionToth()
        peticionMartinez()
        peticionDaveri()
        peticionSoria()
    }, [])

// Datos LUIS AMARAL

    const dataAmaral = {
        labels: mesAmaral,/* datos de la X */
        datasets: [{
            label: 'Luis Amaral',
            backgroundColor: '#85a5ff',
            borderColor: '#597ef7',
            fill: false,
            lineTension: 0,
            pointRadius: 5,
            pointStyle: 'rectRot',
            borderWidth: 1,
            hoverBackgroundColor: '#adc6ff',
            hoverBorderColor: 'white',
            scaleShowGridlLines: false,
            titleDisplay: true,
            data: cantidadAmaral /* datos de la y */
        }]
    };

    const optionsAmaral = {
        animation: true,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                onHover: 'handleHover',
                onLeave: 'handleLeave',
            },
        },
        scales: {
            y: {
                display: true,
                beginAtZero: true,

                title: {
                    display: true,
                    text: 'Cantidad',
                    padding: { top: 0, left: 0, right: 0, bottom: 30 }
                },
                grid: {
                    display: false
                }
            },
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Mes',
                    padding: { top: 20, left: 0, right: 0, bottom: 0 }
                },
                grid: {
                    display: false
                },
            }
        }
    }

    // Datos DARWIN RAU

    const dataRau = {
        labels: mesRau,/* datos de la X */
        datasets: [{
            label: 'Darwin Rau',
            backgroundColor: '#b37feb',
            borderColor: '#9254de',
            fill: false,
            lineTension: 0,
            pointRadius: 5,
            pointStyle: 'rectRot',
            borderWidth: 1,
            hoverBackgroundColor: '#efdbff',
            hoverBorderColor: 'white',
            scaleShowGridlLines: false,
            titleDisplay: true,
            data: cantidadRau /* datos de la y */
        }]
    };

    const optionsRau = {
        animation: true,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                onHover: 'handleHover',
                onLeave: 'handleLeave',
            },
        },
        scales: {
            y: {
                display: true,
                beginAtZero: true,

                title: {
                    display: true,
                    text: 'Cantidad',
                    padding: { top: 0, left: 0, right: 0, bottom: 30 }
                },
                grid: {
                    display: false
                }
            },
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Mes',
                    padding: { top: 20, left: 0, right: 0, bottom: 0 }
                },
                grid: {
                    display: false
                },
            }
        }
    }

    // Datos GONZALO TOTH

    const dataToth = {
        labels: mesToth,/* datos de la X */
        datasets: [{
            label: 'Gonzalo Toth',
            backgroundColor: '#ffc069',
            borderColor: '#ffa940',
            fill: false,
            lineTension: 0,
            pointRadius: 5,
            pointStyle: 'rectRot',
            borderWidth: 1,
            hoverBackgroundColor: '#ffd591',
            hoverBorderColor: 'white',
            scaleShowGridlLines: false,
            titleDisplay: true,
            data: cantidadToth /* datos de la y */
        }]
    };

    const optionsToth = {
        animation: true,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                onHover: 'handleHover',
                onLeave: 'handleLeave',
            },
        },
        scales: {
            y: {
                display: true,
                beginAtZero: true,

                title: {
                    display: true,
                    text: 'Cantidad',
                    padding: { top: 0, left: 0, right: 0, bottom: 30 }
                },
                grid: {
                    display: false
                }
            },
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Mes',
                    padding: { top: 20, left: 0, right: 0, bottom: 0 }
                },
                grid: {
                    display: false
                },
            }
        }
    }

    // Datos JULIAN MARTINEZ

    const dataMartinez = {
        labels: mesMartinez,/* datos de la X */
        datasets: [{
            label: 'Juliàn Martinez',
            backgroundColor: '#95de64',
            borderColor: '#73d13d',
            fill: false,
            lineTension: 0,
            pointRadius: 5,
            pointStyle: 'rectRot',
            borderWidth: 1,
            hoverBackgroundColor: '#b7eb8f',
            hoverBorderColor: 'white',
            scaleShowGridlLines: false,
            titleDisplay: true,
            data: cantidadMartinez /* datos de la y */
        }]
    };

    const optionsMartinez = {
        animation: true,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                onHover: 'handleHover',
                onLeave: 'handleLeave',
            },
        },
        scales: {
            y: {
                display: true,
                beginAtZero: true,

                title: {
                    display: true,
                    text: 'Cantidad',
                    padding: { top: 0, left: 0, right: 0, bottom: 30 }
                },
                grid: {
                    display: false
                }
            },
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Mes',
                    padding: { top: 20, left: 0, right: 0, bottom: 0 }
                },
                grid: {
                    display: false
                },
            }
        }
    }

    // Datos PABLO DAVERI

    const dataDaveri = {
        labels: mesDaveri,/* datos de la X */
        datasets: [{
            label: 'Pablo Daveri',
            backgroundColor: '#5cdbd3',
            borderColor: '#36cfc9',
            fill: false,
            lineTension: 0,
            pointRadius: 5,
            pointStyle: 'rectRot',
            borderWidth: 1,
            hoverBackgroundColor: '#87e8de',
            hoverBorderColor: 'white',
            scaleShowGridlLines: false,
            titleDisplay: true,
            data: cantidadDaveri /* datos de la y */
        }]
    };

    const optionsDaveri = {
        animation: true,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                onHover: 'handleHover',
                onLeave: 'handleLeave',
            },
        },
        scales: {
            y: {
                display: true,
                beginAtZero: true,

                title: {
                    display: true,
                    text: 'Cantidad',
                    padding: { top: 0, left: 0, right: 0, bottom: 30 }
                },
                grid: {
                    display: false
                }
            },
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Mes',
                    padding: { top: 20, left: 0, right: 0, bottom: 0 }
                },
                grid: {
                    display: false
                },
            }
        }
    }

    // Datos ROSANA SORIA

    const dataSoria = {
        labels: mesSoria,/* datos de la X */
        datasets: [{
            label: 'Rosana Soria',
            backgroundColor: '#ff7875',
            borderColor: '#ff4d4f',
            fill: false,
            lineTension: 0,
            pointRadius: 5,
            pointStyle: 'rectRot',
            borderWidth: 1,
            hoverBackgroundColor: '#ffa39e',
            hoverBorderColor: 'white',
            scaleShowGridlLines: false,
            titleDisplay: true,
            data: cantidadSoria /* datos de la y */
        }]
    };

    const optionsSoria = {
        animation: true,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                onHover: 'handleHover',
                onLeave: 'handleLeave',
            },
        },
        scales: {
            y: {
                display: true,
                beginAtZero: true,

                title: {
                    display: true,
                    text: 'Cantidad',
                    padding: { top: 0, left: 0, right: 0, bottom: 30 }
                },
                grid: {
                    display: false
                }
            },
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Mes',
                    padding: { top: 20, left: 0, right: 0, bottom: 0 }
                },
                grid: {
                    display: false
                },
            }
        }
    }

    return (
        <div>
            <Divider orientation="left">Màquinas reparadas por mes por técnico</Divider>
            <br></br>
            <Row>
                <Col>
                    <div style={{ width: '450px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                        <Bar data={dataAmaral} options={optionsAmaral} />
                    </div>
                </Col>
                <Col>
                    <div style={{ width: '100px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                        <Divider type='vertical' style={{height: '350px', marginLeft: '50px'}}></Divider>
                    </div>
                </Col>
                <Col>
                    <div style={{ width: '450px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                        <Bar data={dataRau} options={optionsRau} />
                    </div>
                </Col>
                <Col>
                    <div style={{ width: '100px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                        <Divider type='vertical' style={{height: '350px', marginLeft: '50px'}}></Divider>
                    </div>
                </Col>
                <Col>
                    <div style={{ width: '450px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                        <Bar data={dataToth} options={optionsToth} />
                    </div>
                </Col>
            </Row>
            <br></br>
            <Divider orientation="left"></Divider>
            <br></br>
            <Row>
                <Col>
                    <div style={{ width: '450px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                        <Bar data={dataMartinez} options={optionsMartinez} />
                    </div>
                </Col>
                <Col>
                    <div style={{ width: '100px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                        <Divider type='vertical' style={{height: '350px', marginLeft: '50px'}}></Divider>
                    </div>
                </Col>
                <Col>
                    <div style={{ width: '450px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                        <Bar data={dataDaveri} options={optionsDaveri} />
                    </div>
                </Col>
                <Col>
                    <div style={{ width: '100px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                        <Divider type='vertical' style={{height: '350px', marginLeft: '50px'}}></Divider>
                    </div>
                </Col>
                <Col>
                    <div style={{ width: '450px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                        <Bar data={dataSoria} options={optionsSoria} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}