import React, { Component } from 'react';
import { Table, Input, Button, Space } from 'antd'; //npm i antd (instalar)
import Highlighter from 'react-highlight-words'; // npm i react-highlight-words (instalar para que aparezcan pintadas en amarillo los resultados del search)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import { SearchOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import get from "lodash.get"; // npm i --save lodash.get
import isequal from "lodash.isequal"; //npm i --save lodash.isequal
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import CrearTecnico from './CrearTecnico';
import ModificarTecnico from './ModificarTecnico';
import DetalleTecnico from './DetalleTecnico';
import BajaTecnico from './BajaTecnico';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

//const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

export default class ListadoTecnicos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            data: []

        }
    };

    peticionGet = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }
        if (!expiredToken()) {
            axios.get('Tecnico', config)
                .then(response => {
                    this.setState({
                        data: response.data.data,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    componentDidMount() {
        this.peticionGet();
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => { //hay que modificar con respecto al original (ListadoRol) , para que busque dentro de unObjRol
            return get(record, dataIndex)
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text => { //hay que modificar con respecto al original (ListadoRol) , para que busque dentro de unObjRol
            return isequal(this.state.searchedColumn, dataIndex) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            );
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {

        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idTecnico',
                key: 'idTecnico',
                width: '7%',
                ...this.getColumnSearchProps('idTecnico'),
                sorter: (a, b) => a.idTecnico - b.idTecnico,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
                width: '18%',
                ...this.getColumnSearchProps('nombre'),
                sorter: (a, b) => a.nombre.localeCompare(b.nombre),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width: '5%',
                render: fila => (fila === false) ? <CloseCircleTwoTone twoToneColor="#cf1322" /> : <CheckCircleTwoTone twoToneColor="#52c41a" />
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '30%',
                render: fila => (
                    <>
                        <div>
                              <label>
                               <ModificarTecnico
                                    filaSeleccionada={fila}
                                    listaTecnicos={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; 
                           </div>
                             <label>
                                <BajaTecnico
                                    filaSeleccionada={fila}
                                    listaTecnicos={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; 
                            </div>
                            <label>
                                <DetalleTecnico
                                    filaSeleccionada={fila}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Tecnicos</h3>
                </div>
                <br />
               {/* <div style={{ float: 'left' }}>
                    <CloseCircleTwoTone twoToneColor="#cf1322" /> Inactivo {tab} <CheckCircleTwoTone twoToneColor="#52c41a" /> Activo

                </div>
                <br></br>*/}
                <CrearTecnico
                    listaTecnicos={this.peticionGet}
                    datos={this.state.data}
                />
                <br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ pageSize: 50 }}
                    columns={columns}
                    dataSource={this.state.data}
                    rowKey={row => row.idTecnico} />
            </div>
        );
    }
}