import React, { Component } from 'react'
import '../../Estilos/estilos.css';
import { Descriptions, notification, Image } from 'antd'; //npm i antd (instalar)
import { InfoCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)

export default class DetalleMaquina extends Component {

    openNotification = () => {
        const args = {
            description:
                <Descriptions size="small" column={1} title="Maquina - Ficha" contentStyle={{ color: "slategray" }}>

                    <Descriptions.Item label="Número" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.idMaquina} </Descriptions.Item>
                    <Descriptions.Item label="Modelo" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.modelo} </Descriptions.Item>
                    <Descriptions.Item label="Imagen" labelStyle={{ fontWeight: 600 }}>
                        <Image
                            src={'../Imagenes/' + this.props.filaSeleccionada.imagen}
                            preview={false}>
                        </Image>
                    </Descriptions.Item>
                    <Descriptions.Item label="Tipo" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjTipo.tipo1}</Descriptions.Item>

                </Descriptions>,
            duration: 0,
        };
        return (
            notification.open(args)
        );
    }

    render() {

        return (
            <div>
                <InfoCircleTwoTone
                    twoToneColor="#7cb305"
                    style = {{fontSize:'15px'}}                    
                    onClick={() => this.openNotification()} />  
            </div>
        )
    }
}