import React, { useEffect, useState } from 'react';
import axios from 'axios';   //npm install axios (instalar)
import { Row, Col, Divider } from 'antd';
import {    
    RightCircleFilled,
    FileTextOutlined,
    SnippetsOutlined    
} from '@ant-design/icons'; //npm i antd (instalar)
import { Link } from 'react-router-dom';
import ReparacionesPorTecnicoPorMes from './ReparacionesPorTecnicoProMes';
import { cerrarSesion, expiredToken } from '../Autorizacion';
import { mostrarErrorCatch } from '../Mensajes';
import ReparacionesPorTecnicoEntreFechas from './ReparacionPorTecnicoEntreFechas';

const style1 = { background: '#08979c', padding: '15px 20px', color: 'white' };
const style4 = { background: '#cf1322', padding: '15px 20px', color: 'white' };

export default function InformacionTaller() {
    const [cantReclamosEnCurso, setCantReclamosEnCurso] = useState([]);
    const [cantReclamosPendientes, setCantReclamosPendientes] = useState([]);

    const peticionCantReclamosEnCurso = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Reclamo/CantidadEnCurso', config)
                .then(response => {
                    setCantReclamosEnCurso(response.data.data); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    const peticionCantReclamosPendientes = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Reclamo/CantidadPendiente', config)
                .then(response => {
                    setCantReclamosPendientes(response.data.data); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    useEffect(() => {
        peticionCantReclamosEnCurso()
        peticionCantReclamosPendientes()
    }, [])

    return (
        <div>
            <Divider orientation="left">Resumen del àrea de taller</Divider>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{justifyContent: 'center'}}>
                <Col span={6}>
                    <div style={style1}>
                        <SnippetsOutlined style={{ fontSize: '75px', float: 'right', color: '#8c8c8c' }} />
                        <strong style={{ fontSize: '35px' }}>{cantReclamosEnCurso}</strong>
                        <br></br>
                        <strong style={{ fontSize: '20px' }}>Reclamos en curso </strong>
                        <p></p>
                        <div style={{ background: '#006d75', textAlign: 'center' }}>
                            <Link to={"ListadoReclamosEnCurso"} style={{ color: 'white' }}>
                                Mas Info &nbsp;<RightCircleFilled />
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div style={style4}>
                        <FileTextOutlined style={{ fontSize: '75px', float: 'right', color: '#bfbfbf' }} />
                        <strong style={{ fontSize: '35px' }}> {cantReclamosPendientes}</strong>
                        <br></br>
                        <strong style={{ fontSize: '20px' }}>Reclamos pendientes </strong>
                        <p></p>
                        <div style={{ background: '#a8071a', textAlign: 'center' }}>
                            <Link to={"ListadoReclamosPendientes"} style={{ color: 'white' }}>
                                Mas Info &nbsp;<RightCircleFilled />
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
            <br></br>
            <ReparacionesPorTecnicoPorMes />
            <br></br>
            <ReparacionesPorTecnicoEntreFechas />
        </div>
    )
}