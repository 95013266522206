import React, { Component } from 'react'
import '../../Estilos/estilos.css';
import { Descriptions, notification, Image } from 'antd'; //npm i antd (instalar)
import { InfoCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

export default class DetalleProducto extends Component {

    openNotification = () => {
        const args = {
            description:
                <Descriptions size="small" column={1} title="Maquina - Ficha" contentStyle={{ color: "slategray" }}>

                    <Descriptions.Item label="Número" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.idProducto} </Descriptions.Item>
                    <Descriptions.Item label="Modelo" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.titulo} </Descriptions.Item>
                    <Descriptions.Item label="Precio" labelStyle={{ fontWeight: 600 }}> $ {(this.props.filaSeleccionada.precio).toFixed(2)} </Descriptions.Item>
                    <Descriptions.Item label="Imagen" labelStyle={{ fontWeight: 600 }}>
                        {this.props.filaSeleccionada.idTipoProducto === 1 ?
                            <Image
                                src={'../Imagenes/Capsulas/' + this.props.filaSeleccionada.imagen}
                                width='200px'
                                preview={false} />
                            :
                            this.props.filaSeleccionada.idTipoProducto === 2 ?
                                <Image
                                    src={'../Imagenes/Grano/' + this.props.filaSeleccionada.imagen}
                                    width='100px'
                                    preview={false} />
                                :
                                <Image
                                    src={'../Imagenes/Molido/' + this.props.filaSeleccionada.imagen}
                                    width='100px'
                                    preview={false} />
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="" labelStyle={{ fontWeight: 600, color: 'white' }}>
                        {this.props.filaSeleccionada.idTipoProducto === 1 ?
                            <Image
                                src={'../Imagenes/Capsulas/' + this.props.filaSeleccionada.descripcion}
                                width='350px'
                                preview={false} />
                            :
                            this.props.filaSeleccionada.idTipoProducto === 2 ?
                                <Image
                                    src={'../Imagenes/Grano/' + this.props.filaSeleccionada.descripcion}
                                    width='300px'
                                    preview={false} />
                                :
                                <Image
                                    src={'../Imagenes/Molido/' + this.props.filaSeleccionada.descripcion}
                                    width='300px'
                                    preview={false} />
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Tipo" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjTipoProducto.descripcion}</Descriptions.Item>
                    <Descriptions.Item label="Activo" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.activo === false ? <CloseCircleTwoTone twoToneColor="#cf1322" /> : <CheckCircleTwoTone twoToneColor="#52c41a" />}</Descriptions.Item>          

                </Descriptions>,
            duration: 0,
        };
        return (
            notification.open(args)
        );
    }

    render() {

        return (
            <div>
                <InfoCircleTwoTone
                    twoToneColor="#7cb305"
                    style={{ fontSize: '15px' }}
                    onClick={() => this.openNotification()} />
            </div>
        )
    }
}