import React, { Component } from 'react'
import { Link } from 'react-router-dom'; //npm install react-router-dom (instalar)
import { Menu } from 'antd'; //npm i antd (instalar)
import {
    PieChartOutlined,
    SettingOutlined,
    TeamOutlined,    
    PhoneOutlined,
    FileTextOutlined,
    FilePdfOutlined,
    ToolOutlined
} from '@ant-design/icons'; //npm i antd (instalar)

const { SubMenu } = Menu;

export default class MenuTaller extends Component {

    render() {
        return (
            <Menu
                theme={this.props.theme}
                defaultSelectedKeys={['1']}
                mode="inline"
            >
                <Menu.Item key="1" icon={<PieChartOutlined />}>
                    <Link to="InicioPrincipal">
                        Resumen
                    </Link>
                </Menu.Item>                
                <SubMenu key="sub6" icon={<SettingOutlined />} title="Administración">                    
                    <Menu.Item key="10" icon={<TeamOutlined />}>
                        <Link to="ListadoTecnicos">
                            Tecnicos
                        </Link>
                    </Menu.Item>                    
                </SubMenu>                
                <SubMenu key="sub8" icon={<ToolOutlined />} title="Area Tecnica">
                    <Menu.Item key="13" icon={<PhoneOutlined />}>
                        <Link to='ListadoReclamos'>
                            Reclamos
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="14" icon={<FileTextOutlined />}>
                        <Link to='ListadoHojasServicio'>
                            Hojas Servicio
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="15" icon={<FilePdfOutlined />}>
                        <Link to='ListadoIngresoMaquinas'>
                            Ingreso Maquinas
                        </Link>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        )
    }
}