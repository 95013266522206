import React, { Component } from 'react';
import { Drawer, Form, Button, Input, Space, Checkbox } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar) 
import { mostrarAlertaModificar, mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esNombreGuion, primeraMayuscula, quitoEspacios } from '../../Paginas/Validaciones';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class ModificarTecnico extends Component {
    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar        
        validacion: false,
        error: false,
        tecnico: {
            idTecnico: '',
            nombre: '',
            activo: ''
        }
    };

    showDrawer = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cargarDatos = async () => {
        await this.setState({
            tecnico: {
                idTecnico: this.props.filaSeleccionada.idTecnico,
                nombre: this.props.filaSeleccionada.nombre,
                activo: this.props.filaSeleccionada.activo
            }
        });
    }

    obtenerDatos = () => {
        return (
            this.props.datos
        )
    }

    validacionForm = async () => {
        if (esNombreGuion(this.state.tecnico.nombre)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            tecnico: {
                ...this.state.tecnico,
                [name]: value
            }
        });
        this.validacionForm();
    }

    checkActivoChange = async (e) => {
        var check = e.target.checked;
        await this.setState({
            tecnico: {
                ...this.state.tecnico,
                activo: check
            }
        })
    }

    handleSubmit = e => {
        e.preventDefault(); // El preventDefault() método cancela el evento si es cancelable, 
        //lo que significa que la acción predeterminada que pertenece al evento no ocurrirá. 
        //Por ejemplo, esto puede ser útil cuando: Al hacer clic en un "Submit" botón, evitar que enviar un formulario. 
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }}
        if (!expiredToken()) {
            this.state.tecnico.nombre = quitoEspacios(this.state.tecnico.nombre);
            this.state.tecnico.nombre = primeraMayuscula(this.state.tecnico.nombre);
            if (this.state.tecnico.nombre !== '') {// verifico que el campo rol.Tiporol no sea vacio                 
                await axios.put('Tecnico', this.state.tecnico, config) // Inserta en la Base de datos el nuevo registro
                    .then(response => {
                        this.onClose(); // Cierra el modal luego del ingreso  
                        this.props.listaTecnicos(); // Refresca la lista con el registro modificado
                        mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente.                        
                    }).catch(error => {
                        if (error.response) {
                            mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                            this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                        }
                    }).catch(errorServer => {
                        errorServer = mostrarErrorCatch();
                    })
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <EditTwoTone 
                    twoToneColor="#d48806"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ error: false });
                    }} />

                <Drawer
                    title="Tecnico - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Rol')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Número">
                            <Input
                                disabled
                                name="idTecnico"
                                type="text"
                                id="idTecnico"
                                size="small"
                                value={this.state.tecnico && this.state.tecnico.idTecnico}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Nombre"
                            required>
                            <Input
                                name="nombre"
                                type="text"
                                id="nombre"
                                minLength={6}
                                maxLength={50}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.tecnico && this.state.tecnico.nombre}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El tecnico solo debe contener letras o guiones')}
                        </Form.Item>
                        <Form.Item
                            label="Activo"
                        >
                            <Checkbox
                                name="activo"
                                id="activo"
                                size='small'
                                onChange={this.checkActivoChange}
                                checked={this.state.tecnico.activo}                            
                            >
                            </Checkbox>
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Actualizar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Actualizar
                                    </Button>
                                }

                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        );
    }
}