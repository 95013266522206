import React, { Component } from 'react';
import { Table, Input, Button, Space } from 'antd'; //npm i antd (instalar)
import Highlighter from 'react-highlight-words'; // npm i react-highlight-words (instalar para que aparezcan pintadas en amarillo los resultados del search)
import { SearchOutlined, MinusCircleTwoTone, CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import get from "lodash.get"; // npm i --save lodash.get
import isequal from "lodash.isequal"; //npm i --save lodash.isequal
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import ModificarReclamo from './ModificarReclamo';
import BajaReclamo from './BajaReclamo';
import DetalleReclamo from './DetalleReclamo';
import { Link } from 'react-router-dom';
import CrearHojaServicio from '../HojaServicio/CrearHojaServicio';

const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

export default class ListadoReclamosEnCurso extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            data: []
        }
    };

    peticionGet = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios.get('Reclamo/EnCurso', config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js        
                .then(response => {
                    let reclamos = response.data.data
                    reclamos.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.
                        let fechaR = moment(new Date(el.fecha));
                        return el.fecha = fechaR.format("DD-MM-YYYY");
                    })
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    //Esto es el ciclo de vida, en clases utilizamos esto y en funciones se usa useEffect().
    // Pinta en pantalla la consulta
    componentDidMount() {
        this.peticionGet();
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => { //hay que modificar con respecto al original (ListadoRol) , para que busque dentro de unObjRol
            return get(record, dataIndex)
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text => { //hay que modificar con respecto al original (ListadoRol) , para que busque dentro de unObjRol
            return isequal(this.state.searchedColumn, dataIndex) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            );
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idReclamo',
                key: 'idReclamo',
                width: '7%',
                ...this.getColumnSearchProps('idReclamo'),
                sorter: (a, b) => a.idReclamo - b.idReclamo,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Fecha ',
                dataIndex: 'fecha',
                key: 'fecha',
                width: '12%',
                ...this.getColumnSearchProps('fecha'),
                sorter: (a, b) => a.fecha.localeCompare(b.fecha),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Cliente',
                dataIndex: ['unObjCliente', 'nombre'],
                key: 'nombre',
                width: '26%',
                ...this.getColumnSearchProps(['unObjCliente', 'nombre']),
                sorter: (a, b) => a.unObjCliente.nombre.localeCompare(b.unObjCliente.nombre),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Aignado',
                dataIndex: ['unObjAsignadoReclamo', 'asignado'],
                key: 'asignado',
                width: '10%',
                ...this.getColumnSearchProps(['unObjAsignadoReclamo', 'asignado']),
                sorter: (a, b) => a.unObjAsignadoReclamo.asignado.localeCompare(b.unObjAsignadoReclamo.asignado),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Estado',
                dataIndex: ['unObjEstadoReclamo', 'estado'],
                key: 'estado',
                width: '10%',
                sorter: (a, b) => a.unObjEstadoReclamo.estado.localeCompare(b.unObjEstadoReclamo.estado),
                sortDirections: ['descend', 'ascend'],
                render: fila => (fila === 'Pendiente') ? <CloseCircleTwoTone twoToneColor="#cf1322" /> : (fila === 'En Curso') ? <MinusCircleTwoTone twoToneColor="#fadb14" /> : <CheckCircleTwoTone twoToneColor="#52c41a" />
            },
            {
                title: 'Autor',
                dataIndex: ['unObjUsuario', 'nombre'],
                key: 'nombre',
                width: '10%',
                ...this.getColumnSearchProps(['unObjUsuario', 'nombre']),
                sorter: (a, b) => a.unObjUsuario.nombre.localeCompare(b.unObjUsuario.nombre),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '40%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarReclamo
                                    filaSeleccionada={fila}
                                    listaReclamos={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp;
                            </div>
                            <label>
                                <BajaReclamo
                                    filaSeleccionada={fila}
                                    listaReclamos={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp;
                            </div>
                            <label>
                                <DetalleReclamo
                                    filaSeleccionada={fila}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            {localStorage.getItem('Usuario') === 'AreaTecnica' || localStorage.getItem('Usuario') === 'Manager' ?
                                <label>
                                    <CrearHojaServicio
                                        filaSeleccionada={fila}
                                        listaReclamosEnCurso={this.peticionGet}
                                        id={2}
                                        datos={this.state.data}
                                    />
                                </label>
                                :
                                ''
                            }
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Reclamos en Curso</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>
                    <Link to="/PaginaPrincipal/ListadoReclamosPendientes">
                        <CloseCircleTwoTone twoToneColor="#cf1322" /> Pendiente
                    </Link>
                    {tab}
                    <MinusCircleTwoTone twoToneColor="#d4b106" /> En Curso
                    {tab}
                    <Link to="/PaginaPrincipal/ListadoReclamos">
                        <CheckCircleTwoTone twoToneColor="#061178" /> Todos
                    </Link>
                </div>
                <br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ pageSize: 100 }}
                    columns={columns}
                    dataSource={this.state.data}
                    rowKey={row => row.idReclamo} />
            </div>
        );
    }
}