import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto'
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import { Bar } from 'react-chartjs-2'; //npm install --save react-chartjs-2 chart.js (instalar)
import axios from 'axios';   //npm install axios (instalar)
import { Button, DatePicker, Divider, Form, Select } from 'antd';
import { cerrarSesion, expiredToken } from '../Autorizacion';
import { mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../Mensajes';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 6,
    },
};

const dateFormat = 'DD-MM-YYYY';

export default function ReparacionesPorTecnicoEntreFechas() {
    const [cantidad, setCantidad] = useState([]);
    const [mes, setMes] = useState([]);
    const [listaTecnicos, setListaTecnicos] = useState([]);
    const [tecnico, setTecnico] = useState([])
    const [idTecnico, setIdTecnico] = useState('');
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [validacionFechaDesde, setValidacionFechaDesde] = useState(false);
    const [validacionFechaHasta, setValidacionFechaHasta] = useState(false);
    const [validacionSelectTecnico, setValidacionSelectTecnico] = useState(false);

    const obtenerTecnicoSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de estado
                .get('Tecnico/Activos', config)
                .then(response => {
                    setListaTecnicos(response.data.data)
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    const obtenerTecnico = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de estado
                .get('Tecnico/Activo/' + idTecnico, config)
                .then(response => {
                    setTecnico(response.data.data)
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    const onChangeFechaDesde = (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            setFechaDesde('');
            setValidacionFechaDesde(true); //al colocar esto se muestra el msj de La fecha no puede ser vacia
        } else {
            setFechaDesde(moment(date).format("YYYY-MM-DD")); // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
            setValidacionFechaDesde(false); //al colocar esto se borra el msj de La fecha no puede ser vacia
        }
    }

    const onChangeFechaHasta = (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            setFechaHasta('');
            setValidacionFechaHasta(true); //al colocar esto se muestra el msj de La fecha no puede ser vacia
        } else {
            setFechaHasta(moment(date).format("YYYY-MM-DD")); // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
            setValidacionFechaHasta(false); //al colocar esto se borra el msj de La fecha no puede ser vacia
        }
    }

    const validacionFechaInicio = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (fechaDesde === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    const validacionFechaFin = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (fechaHasta === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    const validacionTecnico = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (idTecnico === '0' || idTecnico === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    const peticionAmaral = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            setValidacionSelectTecnico(validacionTecnico());
            setValidacionFechaDesde(validacionFechaInicio());
            setValidacionFechaHasta(validacionFechaFin());
            if (validacionSelectTecnico === false) {
                if (validacionFechaDesde === false) {
                    if (validacionFechaHasta === false) {
                        obtenerTecnico();
                        axios.get('IngresoMaquina/SegunFechas' + idTecnico + '/' + fechaDesde + '/' + fechaHasta, config)
                            .then(response => {
                                let ingresoMaquinas = response.data.data
                                ingresoMaquinas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                                    let date = moment(new Date(el.fecha))
                                    return el.fecha = date.format("MM-YYYY");
                                })
                                var respuesta = response.data.data;
                                var auxCantidad = [], auxMes = [];
                                respuesta.map(elemento => ((
                                    auxCantidad.push(elemento.cantidad),
                                    auxMes.push(elemento.fecha)
                                )));
                                setCantidad(auxCantidad)
                                setMes(auxMes)
                                // Cargo en data los resultados de la consulta               
                            })
                    }
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    const buscarTecnico = (e) => {
        e.preventDefault();
        peticionAmaral();
    }

    useEffect(() => {
        obtenerTecnicoSelect()
    }, [])

    // Datos

    const data = {
        labels: mes,/* datos de la X */
        datasets: [{
            label: tecnico.nombre,
            backgroundColor: '#5a5a5a',
            borderColor: '#404040',
            fill: false,
            lineTension: 0,
            pointRadius: 5,
            pointStyle: 'rectRot',
            borderWidth: 1,
            hoverBackgroundColor: '#919191',
            hoverBorderColor: 'white',
            scaleShowGridlLines: false,
            titleDisplay: true,
            data: cantidad /* datos de la y */
        }]
    };

    const options = {
        animation: true,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                onHover: 'handleHover',
                onLeave: 'handleLeave',
            },
        },
        scales: {
            y: {
                display: true,
                beginAtZero: true,

                title: {
                    display: true,
                    text: 'Cantidad',
                    padding: { top: 0, left: 0, right: 0, bottom: 30 }
                },
                grid: {
                    display: false
                }
            },
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Mes',
                    padding: { top: 20, left: 0, right: 0, bottom: 0 }
                },
                grid: {
                    display: false
                },
            }
        }
    }

    return (
        <div>
            <Divider orientation="left">Màquinas reparadas por mes por técnico entre fechas</Divider>
            <br></br>
            <Form {...formItemLayout} layout="horizontal" onSubmitCapture={buscarTecnico}>
                <Form.Item
                    label="Técnico"
                    style={{ justifyContent: 'center' }}
                    required>
                    <Select
                        name="idTecnico"
                        id="idTecnico"
                        showSearch
                        size="small"
                        style={{ width: 250 }}
                        optionFilterProp="children"
                        onChange={(selectedGroup) => {
                            setIdTecnico(selectedGroup)
                            setValidacionSelectTecnico(false)
                        }
                        } //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                        placeholder="Buscar para seleccionar"
                        value={(idTecnico) || "0"}
                    >
                        <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Tecnico </label></Option>
                        {listaTecnicos.map(elemento => (
                            <Option key={elemento.idTecnico} value={elemento.idTecnico}>{elemento.nombre}</Option>
                        ))}
                    </Select>
                    {mostrarMensajeValidacionFooter(validacionSelectTecnico, 'Debe seleccionar un técnico')}
                </Form.Item>
                <Form.Item
                    label="Fecha desde"
                    style={{ justifyContent: 'center' }}
                    required>
                    <DatePicker
                        style={{ width: 250 }}
                        value={fechaDesde !== "" ? moment(fechaDesde) : null}
                        size='small'
                        placeholder='Seleccionar Fecha'
                        format={dateFormat}
                        onChange={onChangeFechaDesde} >
                    </DatePicker>
                    {mostrarMensajeValidacionFooter(validacionFechaDesde, 'Debe seleccionar una fecha')}
                </Form.Item>
                <Form.Item
                    label="Fecha hasta"
                    style={{ justifyContent: 'center' }}
                    required>
                    <DatePicker
                        style={{ width: 250 }}
                        value={fechaHasta !== "" ? moment(fechaHasta) : null}
                        size='small'
                        placeholder='Seleccionar Fecha'
                        format={dateFormat}
                        onChange={onChangeFechaHasta} >
                    </DatePicker>
                    {mostrarMensajeValidacionFooter(validacionFechaHasta, 'Debe seleccionar una fecha')}
                </Form.Item>
                <Form.Item wrapperCol={{ ...formItemLayout, offset: 12 }}>
                    <Button
                        type='primary'
                        htmlType='submit'
                    >
                        Mostrar
                    </Button>
                </Form.Item>
                <br></br>
                {cantidad.length > 0 ?
                    <Form.Item
                        style={{ justifyContent: 'center' }}>
                        <div style={{ width: '450px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                            <Bar data={data} options={options} />
                        </div>
                    </Form.Item> :
                    <div
                        style={{ textAlign: 'center' }}>
                        No hay datos disponibles
                    </div>
                }
            </Form>

        </div>
    )
}