import React, { Component } from 'react';
import { Table, Input, Form, Button } from 'antd'; //npm i antd (instalar)
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarErrorCatch, mostrarMensajeValidacionFooter } from "../../Paginas/Mensajes";
import { expiredToken, cerrarSesion } from "../../Paginas/Autorizacion";
import { esNombreNumeros } from '../../Paginas/Validaciones';
import CrearIngresoMaquina from './CrearIngresoMaquina';
import ModificarIngresoMaquina from './ModificarIngresoMaquina';
import BajaIngresoMaquina from './BajaIngresoMaquina';
import DetalleIngresoMaquina from './DetalleIngresoMaquina';

export default class ListadoIngresoMaquinas extends Component {

    state = {
        data: [],
        paginado: [],
        pagina: 0, // usado para cargar el numero de pagina del paginado en el handleChange el cual invoco al final en el onchange del table
        numPlertys: '', // usado para realizar la busqueda por numero de plertys
        validacion: false
    };

    validacionForm = async () => {
        if (!esNombreNumeros(this.state.numPlertys)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    handleChange = async value => { //Obtengo el numero de pagina dentro del paginado
        await this.setState({
            pagina: value
        });
        this.peticionGet();
    }

    handleChangeSearch = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            [name]: value
        });
        this.validacionForm();
    }

    onSearch = async value => {
        await this.setState({
            numPlertys: value
        });
        if (!this.state.validacion && this.state.pagina === 0) { // Debo validar ademas de que ingrese valores correctos que se encuentre en la pagina 0 porque si estoy en la pagina 2 por ej y escribo un nombre no lo va a encontrar            
            this.peticionGet();
        }
        else {
            this.setState({ //Aca si no esta en la pagina 0 lo obligo a que este para poder buscar
                pagina: 0
            })
            this.peticionGet();
        }
    }

    pasarUrl = () => { //Esta funcion es para no escribir .then mas de una vez dentro de peticion get
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }
        if (this.state.numPlertys !== '') {
            return axios.get('IngresoMaquina?numPlertys=' + this.state.numPlertys + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        } else {
            return axios.get('IngresoMaquina?pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
    }

    peticionGet = () => {
        if (!expiredToken()) {
            this.pasarUrl()
                .then(response => {
                    let ingresoMaquinas = response.data.data
                    ingresoMaquinas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.
                        let fechaH = moment(new Date(el.fechaIngreso));
                        let fechaR = moment(new Date(el.fechaReparacion));
                        return el.fechaIngreso = fechaH.format("DD-MM-YYYY"), el.fechaReparacion = fechaR.format("DD-MM-YYYY");
                    })
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta                               
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    componentDidMount() {
        this.peticionGet();
    }

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idIngresoMaquina',
                key: 'idIngresoMaquina',
                width: '7%',
                sorter: (a, b) => a.idIngresoMaquina - b.idIngresoMaquina,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Fecha Reparacion ',
                dataIndex: 'fechaReparacion',
                key: 'fechaReparacion',
                width: '10%',
                sorter: (a, b) => a.fechaReparacion.localeCompare(b.fechaReparacion),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Maquina',
                dataIndex: ['unObjMaquina', 'modelo'],
                key: 'modelo',
                width: '12%',
                sorter: (a, b) => a.unObjMaquina.modelo.localeCompare(b.unObjMaquina.modelo),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Plertys ',
                dataIndex: 'numPlertys',
                key: 'numPlertys',
                width: '8%',
                sorter: (a, b) => a.numPlertys.localeCompare(b.numPlertys),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Fecha Ingreso ',
                dataIndex: 'fechaIngreso',
                key: 'fechaIngreso',
                width: '10%',
                sorter: (a, b) => a.fechaIngreso.localeCompare(b.fechaIngreso),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Motivo',
                dataIndex: ['unObjMotivoIngreso', 'motivo'],
                key: 'motivo',
                width: '8%',
                sorter: (a, b) => a.unObjMotivoIngreso.motivo.localeCompare(b.unObjMotivoIngreso.motivo),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Estado',
                dataIndex: ['unObjEstadoIngresoMaquina', 'estado'],
                key: 'estado',
                width: '10%',
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Tecnico',
                dataIndex: ['unObjTecnico', 'nombre'],
                key: 'nombre',
                width: '14%',
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '50%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarIngresoMaquina
                                    filaSeleccionada={fila}
                                    listaIngresoMaquinas={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaIngresoMaquina
                                    filaSeleccionada={fila}
                                    listaIngresoMaquinas={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetalleIngresoMaquina
                                    filaSeleccionada={fila}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Ingreso de Maquinas</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>
                    <label>
                        <CrearIngresoMaquina
                            listaIngresoMaquinas={this.peticionGet}
                            datos={this.state.data}
                        />
                    </label>
                </div>
                <div style={{ float: 'right' }}>
                    <Form layout="inline" style={{ width: 340 }}>
                        <Form.Item style={{ width: 340 }}>
                            <Input.Search
                                allowClear style={{ width: '100%' }}
                                name="numPlertys"
                                enterButton
                                type="text"
                                id="numPlertys"
                                size="small"
                                maxLength={70}
                                placeholder="Buscar Ingreso de Maquina X Numero de Plertys"
                                onSearch={this.onSearch}
                            >
                            </Input.Search>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'Solo se aceptan letras y numeros')}
                        </Form.Item>
                    </Form>
                </div>
                <br /><br /><br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ total: this.state.paginado.totalRegistros, pageSize: this.state.paginado.registrosPorPagina, onChange: this.handleChange }}
                    columns={columns.filter(col => col.dataIndex !== 'idIngresoMaquina')}
                    dataSource={this.state.data}
                    rowKey={row => row.idIngresoMaquina} />
            </div>
        );
    }
}