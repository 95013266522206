import React, { Component } from 'react'
import { mostrarAlertaEliminado, mostrarExisteEnOtraTabla, mostrarErrorCatch } from '../../Paginas/Mensajes';
import axios from 'axios'; //npm install axios (instalar)
import { Modal, Button } from 'antd'; //npm i antd (instalar)
import { DeleteTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

export default class BajaCliente extends Component {

    state = {
        visible: false, // Abre o cierra el modal eliminar 
        cliente: {
            idCliente: '',
            rut: '',
            nombre: '',
            direccion: '',           
            telefono: '',
            email: ''
        }
    };

    showModal = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cargarDatos = async () => {
        await this.setState({
            cliente: {
                idCliente: this.props.filaSeleccionada.idCliente,
                rut: this.props.filaSeleccionada.cif,
                nombre: this.props.filaSeleccionada.nombre,
                direccion: this.props.filaSeleccionada.direccion,                
                telefono: this.props.filaSeleccionada.telefono,
                email: this.props.filaSeleccionada.email
            }
        });
    }

    peticionDelete = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.delete('Cliente/' + this.state.cliente.idCliente, config)
                .then(response => {
                    this.onClose();
                    mostrarAlertaEliminado();
                    this.props.listaClientes();
                }).catch(error => {
                    if (error.response) {
                        mostrarExisteEnOtraTabla(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                        this.onClose();
                    }
                }).catch(errorServer => {
                    errorServer = mostrarErrorCatch();
                    this.onClose();
                })
        }
        else {
            cerrarSesion();
        }
    }

    render() {
        return (
            <div style={{ float: "left" }}>
                <DeleteTwoTone 
                    twoToneColor="#cf1322"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showModal();
                    }} />

                <Modal
                    visible={this.state.visible}
                    title="Cliente - Eliminar"
                    onCancel={this.onClose}
                    centered
                    maskClosable={false}
                    footer={[
                        <Button onClick={this.onClose}> No </Button>,
                        <Button type='primary' danger onClick={() => this.peticionDelete()}> Si </Button>
                    ]}
                >
                    Esta seguro que desea eliminar el cliente <b>{this.state.cliente && this.state.cliente.nombre}</b>
                </Modal>
            </div>
        )
    }
}