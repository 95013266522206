import React, { Component } from 'react'
import { mostrarAlertaEliminado, mostrarExisteEnOtraTabla, mostrarErrorCatch } from '../../Paginas/Mensajes';
import axios from 'axios'; //npm install axios (instalar)
import { Modal, Button } from 'antd'; //npm i antd (instalar)
import { DeleteTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

export default class BajaIngresoMaquina extends Component {

    state = {
        visible: false, // Abre o cierra el modal eliminar 
        ingresoMaquina: {
            idIngresoMaquina: '',
            fechaIngreso: '',
            idMotivoIngreso: '',
            numSerie: '',
            numPlertys: '',           
            descripcionFalla: '',
            observaciones: '',
            trabajosRealizados: '',
            fechaReparacion: '',
            idEstado: '',
            idTecnico: '',
            idMaquina: '',           
        },
        maquina: '',
    };

    showModal = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cargarDatos = async () => {
        await this.setState({
            ingresoMaquina: {
                idIngresoMaquina: this.props.filaSeleccionada.idIngresoMaquina,
                fechaIngreso: this.props.filaSeleccionada.fechaIngreso,
                idMotivoIngreso: this.props.filaSeleccionada.idMotivoIngreso,
                numSerie: this.props.filaSeleccionada.numSerie,
                numPlertys: this.props.filaSeleccionada.numPlertys,                
                descripcionFalla: this.props.filaSeleccionada.descripcionFalla,
                observaciones: this.props.filaSeleccionada.observaciones,
                trabajosRealizados: this.props.filaSeleccionada.trabajosRealizados,
                fechaReparacion: this.props.filaSeleccionada.fechaReparacion,
                idEstado: this.props.filaSeleccionada.idEstado,
                idTecnico: this.props.filaSeleccionada.idTecnico,
                idMaquina: this.props.filaSeleccionada.idMaquina,               
            },
            maquina: this.props.filaSeleccionada.unObjMaquina.modelo    
        });       
    }

    // DELETE: api/Usuario/5
    peticionDelete = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.delete('IngresoMaquina/' + this.state.ingresoMaquina.idIngresoMaquina, config)
                .then(response => {
                    this.onClose();
                    mostrarAlertaEliminado();
                    this.props.listaIngresoMaquinas();
                }).catch(error => {
                    if (error.response) {
                        mostrarExisteEnOtraTabla(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                        this.onClose();
                    }
                }).catch(errorServer => {
                    errorServer = mostrarErrorCatch();
                    this.onClose();
                })
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <DeleteTwoTone 
                    twoToneColor="#cf1322"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showModal();
                    }} />

                <Modal
                    visible={this.state.visible}
                    title="Ingreso de maquina - Eliminar"
                    onCancel={this.onClose}
                    centered
                    maskClosable={false}
                    footer={[
                        <Button onClick={this.onClose}> No </Button>,
                        <Button type='primary' danger onClick={() => this.peticionDelete()}> Si </Button>
                    ]}
                >
                    Esta seguro que desea eliminar el ingreso de maquina <b>{this.state.ingresoMaquina && this.state.ingresoMaquina.idIngresoMaquina}</b> correspondiente a la maquina <b>{this.state && this.state.maquina}</b>
                </Modal>
            </div>
        )
    }
}