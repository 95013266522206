import React, { Component } from 'react'
import { Link } from 'react-router-dom'; //npm install react-router-dom (instalar)
import { Menu } from 'antd'; //npm i antd (instalar)
import {
    PieChartOutlined,    
    PhoneOutlined,
    ShoppingCartOutlined,
    UsergroupDeleteOutlined,    
    ToolOutlined    
} from '@ant-design/icons'; //npm i antd (instalar)

const { SubMenu } = Menu;

export default class MenuRecepcion extends Component {

    render() {
        return (
            <Menu
                theme={this.props.theme}
                defaultSelectedKeys={['1']}
                mode="inline"
            >
                <Menu.Item key="1" icon={<PieChartOutlined />}>
                    <Link to="InicioPrincipal">
                        Resumen
                    </Link>
                </Menu.Item>                 
                <SubMenu key="sub7" icon={<ShoppingCartOutlined />} title="Ventas">
                    <Menu.Item key="12" icon={<UsergroupDeleteOutlined />}>
                        <Link to='ListadoClientes'>
                            Clientes
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="sub8" icon={<ToolOutlined />} title="Area Tecnica">
                    <Menu.Item key="13" icon={<PhoneOutlined />}>
                        <Link to='ListadoReclamos'>
                            Reclamos
                        </Link>
                    </Menu.Item>                    
                </SubMenu>
            </Menu>
        )
    }
}