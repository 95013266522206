import React, { Component } from 'react';
import { Drawer, Form, Button, Input, Space } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter, mostrarAlertaModificar } from '../../Paginas/Mensajes';
import { esCorreo, esNombreNumeros, esNumero, primeraMayuscula, quitoEspacios, todasMayusculas } from '../../Paginas/Validaciones';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class ModificarCliente extends Component {
    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        i: 0,
        ultimoCliente: 0,
        validacionNombre: false,        
        validacionRut: false,
        validacionDireccion: false,       
        validacionTelefono: false,
        validacionEmail: false,
        error: false,
        cliente: {
            idCliente: '',
            rut: '',
            nombre: '',            
            direccion: '',            
            telefono: '',
            email: ''
        }
    };

    showDrawer = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
            validacionEmail: false
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cargarDatos = async () => {
        await this.setState({
            cliente: {
                idCliente: this.props.filaSeleccionada.idCliente,
                rut: this.props.filaSeleccionada.rut,
                nombre: this.props.filaSeleccionada.nombre,                
                direccion: this.props.filaSeleccionada.direccion,                
                telefono: this.props.filaSeleccionada.telefono,
                email: this.props.filaSeleccionada.email
            }
        });
    }

    obtenerDatos = () => {
        return (
            this.props.datos
        )
    }

    validacionForm = async () => {
        if (esNombreNumeros(this.state.cliente.nombre)) {
            await this.setState({ validacionNombre: true })
        }
        else {
            await this.setState({ validacionNombre: false })
        }        
        if (esNumero(this.state.cliente.rut)) {
            await this.setState({ validacionRut: true })
        }
        else {
            await this.setState({ validacionRut: false })
        }
        if (esNombreNumeros(this.state.cliente.direccion)) {
            await this.setState({ validacionDireccion: true })
        }
        else {
            await this.setState({ validacionDireccion: false })
        }     
        if (esNombreNumeros(this.state.cliente.telefono)) {
            await this.setState({ validacionTelefono: true })
        }
        else {
            await this.setState({ validacionTelefono: false })
        }
    }

    validacionFormCorreo = async () => {
        if (this.state.cliente.email !== '') { // Al esta vacio el campo lo valida como error ya que '' no es direccion de cooreo valida
            if (esCorreo(this.state.cliente.email)) {
                await this.setState({ validacionEmail: false })
            }
            else {
                await this.setState({ validacionEmail: true })
            }
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            cliente: {
                ...this.state.cliente,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleChangeCorreo = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            cliente: {
                ...this.state.cliente,
                [name]: value
            }
        });
        this.validacionFormCorreo();
    }

    handleSubmit = e => {
        e.preventDefault(); 
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    conversiones = () => {
        this.state.cliente.nombre = quitoEspacios(this.state.cliente.nombre);
        this.state.cliente.nombre = primeraMayuscula(this.state.cliente.nombre);       
        this.state.cliente.direccion = quitoEspacios(this.state.cliente.direccion);
        this.state.cliente.direccion = primeraMayuscula(this.state.cliente.direccion);      
        this.state.cliente.telefono = quitoEspacios(this.state.cliente.telefono);
        this.state.cliente.telefono = todasMayusculas(this.state.cliente.telefono);
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            this.conversiones();
            await axios.put('Cliente', this.state.cliente, config) // Inserta en la Base de datos el nuevo registro
                .then(response => {
                    this.props.listaClientes(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABrol>
                    this.onClose(); // Cierra el modal luego del ingreso  
                    mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearRol.js
                }).catch(error => {
                    if (error.response) {
                        mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                        this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                    }
                }).catch(errorServer => {
                    errorServer = mostrarErrorCatch();
                })
        }
        else {
            cerrarSesion();
        }
    }

    render() {
        return (
            <div style={{ float: "left" }}>
                <EditTwoTone 
                    twoToneColor="#d48806"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ error: false });
                    }} />

                <Drawer
                    title="Cliente - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Cliente')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Número">
                            <Input
                                disabled
                                name="idCliente"
                                type="text"
                                id="idCliente"
                                size="small"
                                value={this.state.cliente && this.state.cliente.idCliente}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Rut"
                            required>
                            <Input
                                name="rut"
                                type="text"
                                id="rut"
                                minLength={12}
                                maxLength={12}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.rut}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionCif, 'El rut solo debe contener numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Nombre"
                            required>
                            <Input
                                name="nombre"
                                type="text"
                                id="nombre"
                                minLength={6}
                                maxLength={200}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.nombre}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNombre, 'El nombre del cliente solo debe contener letras y numeros')}
                        </Form.Item>                        
                        <Form.Item
                            label="Direccion"
                            required>
                            <Input
                                name="direccion"
                                type="text"
                                id="direccion"
                                minLength={6}
                                maxLength={200}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.direccion}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionDireccion, 'La direccion solo debe contener letras y numeros')}
                        </Form.Item>                     
                        <Form.Item
                            label="Telefono"
                            required>
                            <Input
                                name="telefono"
                                type="text"
                                id="telefono"
                                minLength={8}
                                maxLength={50}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.telefono}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionTelefono, 'El telefono solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Correo"
                        >
                            <Input
                                name="email"
                                type="email"
                                id="email"
                                maxLength={70}
                                size="small"
                                onChange={this.handleChangeCorreo}
                                value={this.state.cliente && this.state.cliente.email}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionCorreo, 'El correo no es correcto')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacionNombre && !this.state.validacionRut && !this.state.validacionDireccion && !this.state.validacionTelefono &&
                                 !this.state.validacionEmail ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Actualizar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Actualizar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        );
    }
}