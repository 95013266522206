import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, Image } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { Select } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaModificar, mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esNombreNumeros, primeraMayuscula, quitoEspacios } from '../../Paginas/Validaciones';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class ModificarMaquina extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar      
        error: false,
        validacion: false,
        validacionS: false,
        imagenes: null,
        maquina: {
            idMaquina: '',
            modelo: '',
            imagen:'',
            idTipo: '',
        },
        tipo: [],
    };

    showDrawer = () => {
        this.cargarDatos();
        this.obtenerTipoSelect();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacionS: false // desaparece el cartel de validacion al cancelar (Select)
        });
    };

    cargarDatos = async (e) => {
        await this.setState({
            maquina: {
                idMaquina: this.props.filaSeleccionada.idMaquina,
                modelo: this.props.filaSeleccionada.modelo,
                imagen: this.props.filaSeleccionada.imagen,
                idTipo: this.props.filaSeleccionada.idTipo
            },
            imagenes: null,
        });
    }

    validacionForm = async () => {
        if (!esNombreNumeros(this.state.maquina.modelo)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            maquina: {
                ...this.state.maquina,
                [name]: value
            }
        });
        this.validacionForm();
    }

    obtenerTipoSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de roles
                .get('Tipo', config)
                .then(response => {
                    this.setState({ tipo: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    validacionSelect = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.maquina.idTipo === '0' || this.state.maquina.idTipo === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    handleFileChange = e => {
        this.setState({
            [e.target.name]: e.target.files,
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            this.state.maquina.modelo = quitoEspacios(this.state.maquina.modelo);
            this.state.maquina.modelo = primeraMayuscula(this.state.maquina.modelo);
            if (this.state.maquina.modelo !== '') {// verifico que el campo rol.Tiporol no sea vacio 
                await this.setState({ validacionS: this.validacionSelect() });
                if (this.state.validacionS === false) { // Si no selecciona el portfolio muestro el mensaje de error y no entro al post
                    const formData = new FormData();
                    if (this.state.imagenes != null) {

                        for (let index = 0; index < this.state.imagenes.length; index++) {
                            formData.append('imagen', this.state.imagenes[index]);
                        }
                        //console.log('img', formData.get('imagen')) //esta linea es para ver lo que hay en el append, ya que si coloco solo formData no me muestra nada
                    }
                    await axios.put('Maquina?idMaquina=' + this.state.maquina.idMaquina + '&idTipo=' + this.state.maquina.idTipo + '&modelo=' + this.state.maquina.modelo, formData, config)
                        .then(response => {
                            this.props.listaMaquinas(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABrol>
                            this.onClose(); // Cierra el modal luego del ingreso  
                            mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearRol.js                       
                        }).catch(error => {
                            if (error.response) {
                                mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                                this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                            }
                        }).catch(errorServer => {
                            errorServer = mostrarErrorCatch();
                        })
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <EditTwoTone
                    twoToneColor="#d48806"
                    style={{ fontSize: '15px' }}
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ error: false });
                    }} />

                <Drawer
                    title="Maquina - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Maquina')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                disabled
                                name="idMaquina"
                                type="text"
                                id="idMaquina"
                                size="small"
                                value={this.state.maquina && this.state.maquina.idMaquina}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Modelo"
                            required>
                            <Input
                                name="modelo"
                                type="text"
                                id="modelo"
                                minLength={6}
                                maxLength={16}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.maquina && this.state.maquina.modelo}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El modelo solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Imagen">
                            <Image src={'../Imagenes/' + this.state.maquina.imagen}>
                            </Image>
                        </Form.Item>
                        <Form.Item
                            label="Imagen">
                            <Input
                                style={{ border: 0 }}
                                name="imagenes"
                                id="imagenes"
                                type="file"
                                size="small"
                                accept=".jpeg, .png, .jpg"
                                onChange={this.handleFileChange}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Tipo"
                            required
                        >
                            <Select
                                name="idtipo"
                                id="idtipo"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        maquina: {
                                            ...this.state.maquina,
                                            idTipo: selectedGroup
                                        },
                                        validacionS: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.maquina && this.state.maquina.idTipo) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Seleccionar tipo </label></Option>
                                {this.state.tipo.map(elemento => (
                                    <Option key={elemento.idTipo} value={elemento.idTipo}>{elemento.tipo1}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionS, 'Debe seleccionar un tipo')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Modificar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Modificar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}