import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, DatePicker, Empty, Checkbox } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { Select } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)
import { mostrarAlertaError, mostrarErrorCatch, mostrarMensajeValidacionFooter, mostrarMensajeFooter1, mostrarAlertaModificar } from '../../Paginas/Mensajes';
import { comienzoOracionMayuscula, esNombreNumeros, esDescripcion, quitoEspacios, todasMayusculas } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class ModificarIngresoMaquina extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar        
        error: false,
        detalleError: '',
        validacionFechaIngreso: false,
        validacionSelectMotivo: false,
        validacionNumSerie: false,
        validacionNumPlertys: false,
        validacionDescripcionFalla: false,
        validacionObservaciones: false,
        validacionTrabajosRealizados: false,
        validacionSelectEstado: false,
        validacionSelectTecnico: false,
        validacionSelectMaquina: false,
        fechaModificada: false,
        fechaReparacionModificada: false,
        ingresoMaquina: {
            idIngresoMaquina: '',
            fechaIngreso: '',
            idMotivoIngreso: '',
            numSerie: '',
            numPlertys: '',            
            descripcionFalla: '',
            observaciones: '',
            trabajosRealizados: '',
            fechaReparacion: '',
            idEstado: '',
            idTecnico: '',
            idMaquina: '',
        },
    motivoIngreso: [],
    estado: [],
    tecnico: [],
    maquina: []
    };

    showDrawer = () => {
        this.cargarDatos();
        this.obtenerMotivoIngresoSelect();
        this.obtenerEstadoIngresoSelect();
        this.obtenerTecnicoSelect();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacionFechaIngreso: false,
            validacionSelectMotivo: false,
            validacionNumSerie: false,
            validacionNumPlertys: false,
            validacionDescripcionFalla: false,
            validacionObservaciones: false,
            validacionTrabajosRealizados: false,
            validacionSelectEstado: false,
            validacionSelectTecnico: false,
            validacionSelectMaquina: false,
            fechaModificada: false,
            fechaReparacionModificada: false
        });
    };

    obtenerMotivoIngresoSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de estado
                .get('MotivoIngreso', config)
                .then(response => {
                    this.setState({ motivoIngreso: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    obtenerEstadoIngresoSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de estado
                .get('EstadoIngresoMaquina', config)
                .then(response => {
                    this.setState({ estado: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    obtenerTecnicoSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de estado
                .get('Tecnico/Activos', config)
                .then(response => {
                    this.setState({ tecnico: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    obtenerMaquinaSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de estado
                .get('Maquina', config)
                .then(response => {
                    this.setState({ maquina: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    cargarDatos = async () => {
        await this.setState({
            ingresoMaquina: {
                idIngresoMaquina: this.props.filaSeleccionada.idIngresoMaquina,
                fechaIngreso: this.props.filaSeleccionada.fechaIngreso,
                idMotivoIngreso: this.props.filaSeleccionada.idMotivoIngreso,
                numSerie: this.props.filaSeleccionada.numSerie,
                numPlertys: this.props.filaSeleccionada.numPlertys,                
                descripcionFalla: this.props.filaSeleccionada.descripcionFalla,
                observaciones: this.props.filaSeleccionada.observaciones,
                trabajosRealizados: this.props.filaSeleccionada.trabajosRealizados,
                fechaReparacion: this.props.filaSeleccionada.fechaReparacion,
                idEstado: this.props.filaSeleccionada.idEstado,
                idTecnico: this.props.filaSeleccionada.idTecnico,
                idMaquina: this.props.filaSeleccionada.idMaquina,                
            }           
        });
        this.obtenerMotivoIngresoSelect();
        this.obtenerEstadoIngresoSelect();
        this.obtenerTecnicoSelect();
        this.obtenerMaquinaSelect();
    }

    validacionForm = async () => {
        if (!esNombreNumeros(this.state.ingresoMaquina.numSerie)) {
            await this.setState({ validacionNumSerie: false })
        }
        else {
            await this.setState({ validacionNumSerie: true })
        }
        if (!esNombreNumeros(this.state.ingresoMaquina.numPlertys)) {
            await this.setState({ validacionNumPlertys: false })
        }
        else {
            await this.setState({ validacionNumPlertys: true })
        }
        if (!esDescripcion(this.state.ingresoMaquina.descripcionFalla)) {
            await this.setState({ validacionDescripcionFalla: false })
        }
        else {
            await this.setState({ validacionDescripcionFalla: true })
        }
        if (!esDescripcion(this.state.ingresoMaquina.observaciones)) {
            await this.setState({ validacionObservaciones: false })
        }
        else {
            await this.setState({ validacionObservaciones: true })
        }
        if (!esDescripcion(this.state.ingresoMaquina.trabajosRealizados)) {
            await this.setState({ validacionTrabajosRealizados: false })
        }
        else {
            await this.setState({ validacionTrabajosRealizados: true })
        }        
    }

    validacionSelectMotivoIngreso = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.ingresoMaquina.idMotivoIngreso === '0' || this.state.ingresoMaquina.idMotivoIngreso === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionSelectEstadoIngreso = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.ingresoMaquina.idEstado === '0' || this.state.ingresoMaquina.idEstado === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionSelectTecnico = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.ingresoMaquina.idTecnico === '0' || this.state.ingresoMaquina.idTecnico === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionSelectMaquina = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.ingresoMaquina.idMaquina === '0' || this.state.ingresoMaquina.idMaquina === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionFechaIngreso = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.ingresoMaquina.fechaIngreso === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    onChangeFechaIngreso = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                ingresoMaquina: {
                    ...this.state.ingresoMaquina,
                    fechaIngreso: ''
                },
                validacionFechaIngreso: true, //al colocar esto se muestra el msj de La fecha no puede ser vacia
                fechaModificada: false
            })
        } else {
            await this.setState({
                ingresoMaquina: {
                    ...this.state.ingresoMaquina,
                    fechaIngreso: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionFechaIngreso: false, //al colocar esto se borra el msj de La fecha no puede ser vacia
                fechaModificada: true
            })
        }
    }

    onChangeFechaReparacion = async (date) => { // Es llamado en el datePicker  
        let fechaActual = moment().format("DD-MM-YYYY")     
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                ingresoMaquina: {
                    ...this.state.ingresoMaquina,
                    fechaReparacion: fechaActual,
                },
                fechaReparacionModificada: false
            })
        } else {
            await this.setState({
                ingresoMaquina: {
                    ...this.state.ingresoMaquina,
                    fechaReparacion: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                fechaReparacionModificada: true
            })
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            ingresoMaquina: {
                ...this.state.ingresoMaquina,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => { // es llamado al presionar el boton Insertar en el form
        e.preventDefault();
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    conversiones = () => {
        this.state.ingresoMaquina.numSerie = quitoEspacios(this.state.ingresoMaquina.numSerie);
        this.state.ingresoMaquina.numSerie = todasMayusculas(this.state.ingresoMaquina.numSerie);
        this.state.ingresoMaquina.numPlertys = quitoEspacios(this.state.ingresoMaquina.numPlertys);
        this.state.ingresoMaquina.numPlertys = todasMayusculas(this.state.ingresoMaquina.numPlertys);
        this.state.ingresoMaquina.descripcionFalla = quitoEspacios(this.state.ingresoMaquina.descripcionFalla);
        this.state.ingresoMaquina.descripcionFalla = comienzoOracionMayuscula(this.state.ingresoMaquina.descripcionFalla);
        this.state.ingresoMaquina.observaciones = quitoEspacios(this.state.ingresoMaquina.observaciones);
        this.state.ingresoMaquina.observaciones = comienzoOracionMayuscula(this.state.ingresoMaquina.observaciones);
        this.state.ingresoMaquina.trabajosRealizados = quitoEspacios(this.state.ingresoMaquina.trabajosRealizados);
        this.state.ingresoMaquina.trabajosRealizados = comienzoOracionMayuscula(this.state.ingresoMaquina.trabajosRealizados);
    }

    reverseFecha = async () => {
        if (this.state.fechaModificada === false) {
            let fecham = this.state.ingresoMaquina.fechaIngreso.split('-').reverse().join('-')
            await this.setState({ // Cago el la fecha invertida 
                ingresoMaquina: {
                    ...this.state.ingresoMaquina,
                    fechaIngreso: fecham,
                }
            });
        }
        return this.state.ingresoMaquina.fechaIngreso
    }

    reverseFechaReparacion = async () => {
        console.log('FR', this.state.ingresoMaquina.fechaReparacion)
        if (this.state.fechaReparacionModificada === false) {
            let fecham = this.state.ingresoMaquina.fechaReparacion.split('-').reverse().join('-')
            await this.setState({ // Cago el la fecha invertida 
                ingresoMaquina: {
                    ...this.state.ingresoMaquina,
                    fechaReparacion: fecham,
                }
            });
        }
        console.log('FR', this.state.ingresoMaquina.fechaReparacion)
        return this.state.ingresoMaquina.fechaReparacion
        
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }

        if (!expiredToken()) {       
            this.conversiones();
            await this.setState({ validacionFechaIngreso: this.validacionFechaIngreso() }) // Devuleve true si no ingrese fecha, false en caso contrario 
            await this.setState({ validacionSelectMotivo: this.validacionSelectMotivoIngreso() });
            await this.setState({ validacionSelectEstado: this.validacionSelectEstadoIngreso() });
            await this.setState({ validacionSelectTecnico: this.validacionSelectTecnico() });
            if (this.state.validacionFechaIngreso === false) { // Si no selecciona la fecha de inicio muestro el mensaje de error y no entro al post 
                if (this.state.validacionSelectMotivo === false) {
                    if (this.state.validacionSelectEstado === false) {
                        if (this.state.validacionSelectTecnico === false) {  
                            if (this.state.validacionSelectMaquina === false) {                          
                            this.reverseFechaReparacion();
                            this.reverseFecha();
                         //   console.log('Dat', this.state.ingresoMaquina)
                            await axios.put('IngresoMaquina', this.state.ingresoMaquina, config) // Inserta en la Base de datos el nuevo registro                            
                                .then(response => {
                                    this.props.listaIngresoMaquinas(); // obtengo la lista de clientes pasada por props desde ListadoClientes.js (Al clrear el trabajo vuelve a mostrarme la lista de clientes)
                                    this.onClose(); // Cierra el modal luego del ingreso  
                                    mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearPortfolio.js
                                }).catch(error => {
                                    if (error.response) {
                                        if (error.response.data.errors.fecha) { // al tener la fecha un array de errores selecciono el que me interesa desde la api
                                            mostrarAlertaError(error.response.data.errors.Fecha[0])
                                            this.setState({
                                                error: true,
                                                detalleError: error.response.data.errors.Fecha[0]
                                            })
                                        }
                                        else {
                                            mostrarAlertaError(error.response.data.errors[0].detail)
                                            this.setState({
                                                error: true,
                                                detalleError: error.response.data.errors[0].detail
                                            })
                                        }
                                    }
                                }).catch(errorServer => {
                                    errorServer = mostrarErrorCatch();
                                })
                            }
                        }
                    }
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {
        return (
            <div style={{ float: "left" }}>
                <EditTwoTone
                    twoToneColor="#d48806"
                    style={{ fontSize: '15px' }}
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ error: false });
                    }} />


                <Drawer
                    title="Ingreso de maquina - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter1(this.state.error, this.state.detalleError)}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero"                            
                        >
                            <Input
                                disabled                                
                                name="idIngresoMaquina"
                                type="text"
                                id="idIngresoMaquina"
                                size="small"
                                value={this.state.ingresoMaquina && this.state.ingresoMaquina.idIngresoMaquina}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Fecha"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={moment(this.state.ingresoMaquina.fechaIngreso, dateFormat)}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFechaIngreso} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionFechaIngreso, 'Debe seleccionar una fecha')}
                        </Form.Item>
                        <Form.Item
                            label="Maquina"
                            required
                        >
                            <Select
                                name="idMaquina"
                                id="idMaquina"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        ingresoMaquina: {
                                            ...this.state.ingresoMaquina,
                                            idMaquina: selectedGroup
                                        },
                                        validacionSelectMaquina: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.ingresoMaquina && this.state.ingresoMaquina.idMaquina) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Maquina </label></Option>
                                {this.state.maquina.map(elemento => (
                                    <Option key={elemento.idMaquina} value={elemento.idMaquina}>{elemento.modelo}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionSelectMaquina, 'Debe seleccionar una maquina')}
                        </Form.Item>                         
                        <Form.Item
                            label="Nº Serie"
                        >
                            <Input
                                name="numSerie"
                                type="text"
                                id="numSerie"
                                minLength={2}
                                maxLength={20}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.ingresoMaquina && this.state.ingresoMaquina.numSerie}

                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNumSerie, 'El serie solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Nº Plertys"
                            required>
                            <Input
                                name="numPlertys"
                                type="text"
                                id="numPlertys"
                                minLength={2}
                                maxLength={20}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.ingresoMaquina && this.state.ingresoMaquina.numPlertys}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNumPlertys, 'El plertys solo debe contener letras y numeros')}
                        </Form.Item>                        
                        <Form.Item
                            label="Motivo"
                            required
                        >
                            <Select
                                name="idMotivoIngreso"
                                id="idMotivoIngreso"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        ingresoMaquina: {
                                            ...this.state.ingresoMaquina,
                                            idMotivoIngreso: selectedGroup
                                        },
                                        validacionSelectMotivo: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.ingresoMaquina && this.state.ingresoMaquina.idMotivoIngreso) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Motivo de Ingreso </label></Option>
                                {this.state.motivoIngreso.map(elemento => (
                                    <Option key={elemento.idMotivoIngreso} value={elemento.idMotivoIngreso}>{elemento.motivo}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionSelectMotivo, 'Debe seleccionar un motivo de ingreso')}
                        </Form.Item>
                        <Form.Item
                            label="Descripcion Falla"
                            required>
                            <TextArea
                                name="descripcionFalla"
                                type="text"
                                rows={4}
                                id="descripcionFalla"
                                minLength={6}
                                maxLength={500}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.ingresoMaquina && this.state.ingresoMaquina.descripcionFalla}
                                required
                            >
                            </TextArea>
                            {mostrarMensajeValidacionFooter(this.state.validacionDescripcionFalla, 'La descripcion de la falla solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Observaciones"
                        >
                            <TextArea
                                name="observaciones"
                                type="text"
                                rows={4}
                                id="observaciones"
                                minLength={6}
                                maxLength={500}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.ingresoMaquina && this.state.ingresoMaquina.observaciones}

                            >
                            </TextArea>
                            {mostrarMensajeValidacionFooter(this.state.validacionObservaciones, 'La observacion solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Tecnico"
                            required
                        >
                            <Select
                                name="idTecnico"
                                id="idTecnico"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        ingresoMaquina: {
                                            ...this.state.ingresoMaquina,
                                            idTecnico: selectedGroup
                                        },
                                        validacionSelectTecnico: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.ingresoMaquina && this.state.ingresoMaquina.idTecnico) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Tecnico </label></Option>
                                {this.state.tecnico.map(elemento => (
                                    <Option key={elemento.idTecnico} value={elemento.idTecnico}>{elemento.nombre}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionSelectTecnico, 'Debe seleccionar un tecnico')}
                        </Form.Item>
                        <Form.Item
                            label="Trabajos realizados"
                        >
                            <TextArea
                                name="trabajosRealizados"
                                type="text"
                                rows={4}
                                id="trabajosRealizados"
                                minLength={6}
                                maxLength={500}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.ingresoMaquina && this.state.ingresoMaquina.trabajosRealizados}

                            >
                            </TextArea>
                            {mostrarMensajeValidacionFooter(this.state.validacionTrabajosRealizados, 'Los trabajos realizados solo debe contener letras y numeros')}
                        </Form.Item>                        
                        <Form.Item
                            label="Fecha Reparacion"
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={moment(this.state.ingresoMaquina.fechaReparacion, dateFormat)}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFechaReparacion} >
                            </DatePicker>
                        </Form.Item>
                        <Form.Item
                            label="Estado"
                            required
                        >
                            <Select
                                name="idEstado"
                                id="idEstado"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        ingresoMaquina: {
                                            ...this.state.ingresoMaquina,
                                            idEstado: selectedGroup
                                        },
                                        validacionSelectEstado: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.ingresoMaquina && this.state.ingresoMaquina.idEstado) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Estado </label></Option>
                                {this.state.estado.map(elemento => (
                                    <Option key={elemento.idEstado} value={elemento.idEstado}>{elemento.estado}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionSelectEstado, 'Debe seleccionar un estado')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacionNumHojaServicio && !this.state.validacionNumPlertys && !this.state.validacionNumSerie &&
                                    !this.state.validacionObservaciones && !this.state.validacionProcedimientoRealizado ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Actualizar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Actualizar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}