import React, { Component } from 'react'
import { mostrarAlertaEliminado, mostrarExisteEnOtraTabla, mostrarErrorCatch } from '../../Paginas/Mensajes';
import axios from 'axios'; //npm install axios (instalar)
import { Modal, Button } from 'antd'; //npm i antd (instalar)
import { DeleteTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

export default class BajaReclamo extends Component {

    state = {
        visible: false, // Abre o cierra el modal eliminar 
        reclamo: {
            idReclamo: '',
            fecha: '',
            sector: '',
            contacto: '',
            telefonoContacto: '',
            detalle: '',
            idEstado: '',
            resolucion: '',
            idAsignado: '',
            idCliente: '',
            idMaquina: '',
            idUsuario: Number(sessionStorage.getItem('IdUsuario')),
        },      
        Cliente: this.props.filaSeleccionada.unObjCliente.nombre, 
    };

    showModal = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cargarDatos = async () => {
        await this.setState({
            reclamo: {
                idReclamo: this.props.filaSeleccionada.idReclamo,
                fecha: this.props.filaSeleccionada.fecha,
                sector: this.props.filaSeleccionada.sector, 
                contacto: this.props.filaSeleccionada.contacto,
                telefonoContacto: this.props.filaSeleccionada.telefonoContacto,
                detalle: this.props.filaSeleccionada.detalle,
                idEstado: this.props.filaSeleccionada.idEstado,
                resolucion: this.props.filaSeleccionada.resolucion,
                idAsignado: this.props.filaSeleccionada.idAsignado,
                idCliente: this.props.filaSeleccionada.idCliente,
                idMaquina: this.props.filaSeleccionada.idMaquina,
                idUsuario: Number(sessionStorage.getItem('IdUsuario')),
            },
            Cliente: this.props.filaSeleccionada.unObjCliente.nombre, // Guardo el nombre del cliente que viene de listado de clientes          
        });       
    }

    // DELETE: api/Usuario/5
    peticionDelete = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.delete('Reclamo/' + this.state.reclamo.idReclamo, config)
                .then(response => {
                    this.onClose();
                    mostrarAlertaEliminado();
                    this.props.listaReclamos();
                }).catch(error => {
                    if (error.response) {
                        mostrarExisteEnOtraTabla(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                        this.onClose();
                    }
                }).catch(errorServer => {
                    errorServer = mostrarErrorCatch();
                    this.onClose();
                })
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <DeleteTwoTone 
                    twoToneColor="#cf1322"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showModal();
                    }} />

                <Modal
                    visible={this.state.visible}
                    title="Reclamo - Eliminar"
                    onCancel={this.onClose}
                    centered
                    maskClosable={false}
                    footer={[
                        <Button onClick={this.onClose}> No </Button>,
                        <Button type='primary' danger onClick={() => this.peticionDelete()}> Si </Button>
                    ]}
                >
                    Esta seguro que desea eliminar el reclamo <b>{this.state.reclamo && this.state.reclamo.idReclamo}</b> correspondiente al cliente <b>{this.state && this.state.Cliente}</b>
                </Modal>
            </div>
        )
    }
}