import React, { Component } from 'react'
import { mostrarAlertaEliminado, mostrarExisteEnOtraTabla, mostrarErrorCatch } from '../../Paginas/Mensajes';
import axios from 'axios'; //npm install axios (instalar)
import { Modal, Button } from 'antd'; //npm i antd (instalar)
import { DeleteTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

export default class BajaAsignadoReclamo extends Component {

    state = {
        visible: false, // Abre o cierra el modal eliminar 
        asignadoReclamo: {
            idAsignado: '',
            asignado: ''
        },
    };

    showModal = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cargarDatos = () => {
        this.setState({
            asignadoReclamo: {
                idAsignado: this.props.filaSeleccionada.idAsignado,
                asignado: this.props.filaSeleccionada.asignado
            }
        });
    }

    peticionDelete = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.delete('AsignadoReclamo/' + this.state.asignadoReclamo.idAsignado, config)
                .then(response => {
                    this.onClose();
                    mostrarAlertaEliminado();
                    this.props.listaAsignados();
                }).catch(error => {
                    if (error.response) {
                        mostrarExisteEnOtraTabla(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                        this.onClose();
                    }
                }).catch(errorServer => {
                    errorServer = mostrarErrorCatch();
                    this.onClose();
                })
        }
        else {
            cerrarSesion();
        }
    }

    render() {
        return (
            <div style={{ float: "left" }}>
                <DeleteTwoTone
                    twoToneColor="#cf1322"
                    style={{ fontSize: '15px' }}
                    onClick={() => {
                        this.showModal();
                    }} />

                <Modal
                    visible={this.state.visible}
                    title="Asignacion de Reclamo - Eliminar"
                    onCancel={this.onClose}
                    centered
                    maskClosable={false}
                    footer={[
                        <Button onClick={this.onClose}> No </Button>,
                        <Button type='primary' danger onClick={() => this.peticionDelete()}> Si </Button>
                    ]}
                >
                    Esta seguro que desea eliminar la asignacion de reclamo <b>{this.state.asignadoReclamo && this.state.asignadoReclamo.asignado}</b>
                </Modal>
            </div>
        )
    }
}