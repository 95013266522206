import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space } from 'antd'; //npm i antd (instalar)
import { PlusOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import { Select } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaExito, mostrarAlertaError, mostrarMensajeFooter1, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esNombreNumeros, esNumero, esNumeroPunto, primeraMayuscula, quitoEspacios } from '../../Paginas/Validaciones';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class CrearProducto extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        i: 0,
        ultimoProducto: 0,
        error: false,
        detalleError: false,
        validacion: false,
        validacionPrecio: false,
        validacionS: false,
        validacionArchImagen: false,
        validacionArchDescripcion: false,
        imagenes: null,
        descripcion: null,
        producto: {
            idProducto: '',
            titulo: '',
            precio: '',
            idTipoProducto: ''
        },
        tipoProducto: [],
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacionS: false, // desaparece el cartel de validacion al cancelar (Select)
            validacionArchImagen: false,
            validacionArchDescripcion: false,
        });
    };

    obtenerDatos = () => {
        return (
            this.props.datos
        )
    }

    validacionForm = async () => {
        if (!esNombreNumeros(this.state.producto.titulo)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
        if (!esNumeroPunto(this.state.producto.precio)) {
            await this.setState({ validacionPrecio: false })
        }
        else {
            await this.setState({ validacionPrecio: true })
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            producto: {
                ...this.state.producto,
                [name]: value
            }
        });
        this.validacionForm();
    }

    obtenerTipoProductoSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de roles
                .get('TipoProducto', config)
                .then(response => {
                    this.setState({ tipoProducto: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    // en el caso de modificar no necesito resetear los campos ya que si da error necesito que los mismos queden seleccionados
    resertFormCrear = async () => {
        const arreglo = this.obtenerDatos();
        const largo = this.props.datos.length;
        await this.setState({ i: largo - 1 }); // Obtengo la posicion del ultimo dato del arreglo   
        if (this.state.i >= 0) {
            await this.setState({ ultimoProducto: arreglo[this.state.i].idProducto + 1 }); // obtengo el ultimo IdUsuario y le sumo para colocarlo en el value del Modal
        }
        else {
            this.setState({ ultimoProducto: 1 })
        }
        await this.setState({
            producto: {
                idProducto: this.state.ultimoProducto,
                titulo: '',
                precio: '',
                idTipoProducto: '',
            },
            imagenes: null,
            descripcion: null,
        });
        this.obtenerTipoProductoSelect();
    }

    validacionSelect = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.producto.idTipoProducto === '0' || this.state.producto.idTipoProducto === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionArchivoImagen = () => {
        let valida = false
        if (this.state.imagenes === null) {
            valida = true;
        }
        else {
            valida = false
        }
        return valida;
    }

    validacionArchivoDescripcion = () => {
        let valida = false
        if (this.state.descripcion === null) {
            valida = true;
        }
        else {
            valida = false
        }
        return valida;
    }

    handleFileChange = async e => {
        await this.setState({
            [e.target.name]: e.target.files[0],
        })
        if (this.state.imagenes !== null) {
            await this.setState({ validacionArchImagen: false })
        }
        if (this.state.descripcion !== null) {
            await this.setState({ validacionArchDescripcion: false })
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        if (this.state.tipoModal === 'insertar') {
            this.peticionPost();
        }
    }

    peticionPost = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            this.state.producto.titulo = quitoEspacios(this.state.producto.titulo);
            this.state.producto.titulo = primeraMayuscula(this.state.producto.titulo);
            if (this.state.producto.titulo !== '') {// verifico que el campo no sea vacio   
                await this.setState({
                    validacionS: this.validacionSelect(),
                    validacionArchImagen: this.validacionArchivoImagen(),
                    validacionArchDescripcion: this.validacionArchivoDescripcion(),
                });
                if (this.state.validacionS === false) { // Si no selecciona nada muestro el mensaje de error y no entro al post    
                    delete this.state.producto.idProducto;
                    const formData = new FormData();
                    if (this.state.validacionArchImagen === false) {
                        if (this.state.imagenes.name.includes("imagen")) {
                            formData.append('imagenes', this.state.imagenes);
                        }
                        else {
                            formData.append('imagenes', this.state.imagenes, 'imagen' + this.state.imagenes.name);
                        }
                        if (this.state.validacionArchDescripcion === false) {
                            if (this.state.descripcion.name.includes("descripcion")) {
                                formData.append('imagenes', this.state.descripcion);
                            }
                            else {
                                formData.append('imagenes', this.state.descripcion, 'descripcion' + this.state.descripcion.name);
                            }
                            console.log('arch', formData.getAll('imagenes'))
                            await axios.post('Producto?idTipoProducto=' + this.state.producto.idTipoProducto + '&titulo=' + this.state.producto.titulo + '&precio=' + this.state.producto.precio, formData, config)
                                .then(response => {
                                    this.props.listaProductos(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABrol>
                                    this.onClose(); // Cierra el modal luego del ingreso  
                                    mostrarAlertaExito(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearRol.js
                                }).catch(error => {

                                    if (error.response) {
                                        mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                                        this.setState({
                                            error: true,
                                            detalleError: error.response.data.errors[0].detail
                                        }); // cambio el estado de error a true para que se muestre en el pie de drawer
                                    }
                                }).catch(errorServer => {
                                    errorServer = mostrarErrorCatch();
                                })
                        }
                    }
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div>
                <Button type="primary" size='small'
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ tipoModal: 'insertar' });
                        this.resertFormCrear();
                        this.setState({ error: false, detalleError: '' });
                    }}
                >
                    <PlusOutlined /> Nuevo Producto
                </Button>

                <Drawer
                    title="Producto - Nuevo"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter1(this.state.error, this.state.detalleError)}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                disabled
                                name="idProducto"
                                type="text"
                                id="idProducto"
                                size="small"
                                value={this.state.producto && this.state.producto.idProducto}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Titulo"
                            required>
                            <Input
                                name="titulo"
                                type="text"
                                id="titulo"
                                maxLength={16}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.producto && this.state.producto.titulo}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El titulo solo debe contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Precio"
                            required>
                            <Input
                                name="precio"
                                type="text"
                                id="precio"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.producto && this.state.producto.precio}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionPrecio, 'El precio solo debe contener numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Imagen"
                            required>
                            <Input
                                style={{ border: 0 }}
                                name="imagenes"
                                type="file"
                                size="small"
                                accept=".jpeg, .png, .jpg"
                                onChange={this.handleFileChange}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionArchImagen, 'Debe cargar una imagen')}
                        </Form.Item>
                        <Form.Item
                            label="Descripcion"
                            required>
                            <Input
                                style={{ border: 0 }}
                                name="descripcion"
                                type="file"
                                size="small"
                                accept=".jpeg, .png, .jpg"
                                onChange={this.handleFileChange}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionArchDescripcion, 'Debe cargar una decripciona')}
                        </Form.Item>
                        <Form.Item
                            label="Tipo"
                            required
                        >
                            <Select
                                name="idTipoProducto"
                                id="idTipoProducto"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        producto: {
                                            ...this.state.producto,
                                            idTipoProducto: selectedGroup
                                        },
                                        validacionS: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.producto && this.state.producto.idTipoProducto) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Seleccionar tipo </label></Option>
                                {this.state.tipoProducto.map(elemento => (
                                    <Option key={elemento.idTipoProducto} value={elemento.idTipoProducto}>{elemento.descripcion}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionS, 'Debe seleccionar un tipo')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Insertar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Insertar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}