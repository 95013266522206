import React, { Component } from 'react'
import '../../Estilos/estilos.css';
import { Descriptions, Image, Drawer } from 'antd'; //npm i antd (instalar)
import { InfoCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)

const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

export default class DetalleHojaServicio extends Component {
    state = {
        visible: false,
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    render() {

        return (
            <div style={{ float: "left" }}>
                <InfoCircleTwoTone
                    twoToneColor="#7cb305"
                    style={{ fontSize: '15px' }}
                    onClick={() => {
                        this.showDrawer();
                    }} />
                <Drawer
                    title="Reclamo - Ficha"
                    width={500}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}

                >
                    <Descriptions size="default" column={1} contentStyle={{ color: "slategray" }}>

                        <Descriptions.Item label="Número" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.idReclamo} </Descriptions.Item>
                        <Descriptions.Item label="Fecha" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.fecha}</Descriptions.Item>
                        <Descriptions.Item label="Cliente" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjCliente.nombre}</Descriptions.Item>
                        <Descriptions.Item label="Direccion" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjCliente.direccion}</Descriptions.Item>
                        <Descriptions.Item label="Sector" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.sector)}</Descriptions.Item>
                        <Descriptions.Item label="Contacto" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.contacto)}</Descriptions.Item>
                        <Descriptions.Item label="Telefono Contacto" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.telefonoContacto)}</Descriptions.Item>
                        <Descriptions.Item label="Maquina" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjMaquina.modelo}</Descriptions.Item>
                        <Descriptions.Item label="Maquina" labelStyle={{ fontWeight: 600, color: 'white' }}>
                            <Image
                                src={'../Imagenes/' + this.props.filaSeleccionada.unObjMaquina.imagen}
                                preview={false}>
                            </Image>
                        </Descriptions.Item>
                        <Descriptions.Item label="Motivo" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.detalle)}</Descriptions.Item>
                        <Descriptions.Item label="Resolucion" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.resolucion)}</Descriptions.Item>
                        <Descriptions.Item label="Estado" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjEstadoReclamo.estado}</Descriptions.Item>
                        <Descriptions.Item label="Asignado" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjAsignadoReclamo.asignado}</Descriptions.Item>
                        <Descriptions.Item label="Usuario" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjUsuario.nombre}</Descriptions.Item>

                    </Descriptions>
                </Drawer>
            </div >

        )
    }
}