import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { Select } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter, mostrarAlertaModificar } from '../../Paginas/Mensajes';
import { esNombre, esContrasenia, quitoEspacios, primeraMayuscula } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class ModificarUsuario extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        i: 0,
        ultimoUsuario: 0,
        error: false,
        validacion: false,
        validacionP: false,
        validacionS: false,
        usuario: {
            IdUsuario: '',
            Nombre: '',
            Contrasenia: '',
            idRol: ''
        },
        rol: [],
        tipoRol: ''
    };

    showDrawer = () => {
        this.cargarDatos();
        this.obtenerRolSelect();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacion: false, // desaparece el cartel de validacion al cancelar (Nombre)
            validacionP: false, // desaparece el cartel de validacion al cancelar (Contraseña)
            validacionS: false // desaparece el cartel de validacion al cancelar (Select)
        });
    };

    cargarDatos = async () => {
        await this.setState({
            usuario: {
                IdUsuario: this.props.filaSeleccionada.idUsuario,
                Nombre: this.props.filaSeleccionada.nombre,
                Contrasenia: '',
                idRol: this.props.filaSeleccionada.idRol
            },
            tipoRol: this.props.filaSeleccionada.unObjRol.tipoRol
        });
    }

    obtenerDatos = () => {
        return (
            this.props.datos
        )
    }

    validacionForm = async () => {
        if (esNombre(this.state.usuario.Nombre)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
        if (esContrasenia(this.state.usuario.Contrasenia)) {
            await this.setState({ validacionP: false })
        }
        else {
            await this.setState({ validacionP: true })
        }
    }

    validacionSelect = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.usuario.idRol === '0' || this.state.usuario.idRol === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    obtenerRolSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            if (localStorage.getItem('Usuario') === 'Manager') {
                axios // Cargo los datos en el select de roles
                    .get('Rol', config)
                    .then(response => {
                        this.setState({ rol: response.data.data })
                    })
                    .catch(error => {
                        error = mostrarErrorCatch();
                    });
            } else {
                axios // Cargo los datos en el select de roles
                    .get('Rol/SinAdministrador', config)
                    .then(response => {
                        this.setState({ rol: response.data.data })
                    })
                    .catch(error => {
                        error = mostrarErrorCatch();
                    });
            }
        }
        else {
            cerrarSesion();
        }
    }

    handleChange = async (evt) => {
        const name = evt.target.name;
        const value = evt.target.value
        await this.setState({
            usuario: {
                ...this.state.usuario,
                [name]: value,
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => {
        e.preventDefault(); // El preventDefault() método cancela el evento si es cancelable, 
        //lo que significa que la acción predeterminada que pertenece al evento no ocurrirá. 
        //Por ejemplo, esto puede ser útil cuando: Al hacer clic en un "Submit" botón, evitar que enviar un formulario. 
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await this.setState({ validacionS: this.validacionSelect() });
            this.state.usuario.Nombre = quitoEspacios(this.state.usuario.Nombre);
            this.state.usuario.Nombre = primeraMayuscula(this.state.usuario.Nombre);
            if (this.state.validacionS === false) { // Si no selecciona el rol muestro el mensaje de error y no entro al post
                if (this.state.usuario.Nombre !== '') {// verifico que el campo usuario.Nombre no sea vacio                       
                    await axios.put('Usuario/', this.state.usuario, config) // Inserta en la Base de datos el nuevo registro
                        .then(response => {
                            this.props.listaUsuarios(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABrol>
                            this.onClose(); // Cierra el modal luego del ingreso  
                            mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearRol.js
                        }).catch(error => {
                            if (error.response) {
                                mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                                this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                            }
                        }).catch(errorServer => {
                            errorServer = mostrarErrorCatch();
                        })
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {
        return (
            <div style={{ float: "left" }}>
                {/* Muestra el boton modificar en todos los casos menos cuando el usuario logeado no es manager, impido que me modifiquen el usuario administrador si no es manager */}
                {localStorage.getItem('Usuario') === 'Manager' ? //Si es manager muestra el boton modificar 
                    <EditTwoTone
                        twoToneColor="#d48806"
                        style={{ fontSize: '15px' }}
                        onClick={() => {
                            this.showDrawer();
                            this.setState({ error: false });
                        }} />
                    :
                    this.props.filaSeleccionada.idUsuario !== 1 ? // Si es manager no bloquea el boton editar de aquel usuario que es el administrador ( es decir el manager)
                        <EditTwoTone
                            twoToneColor="#d48806"
                            style={{ fontSize: '15px' }}
                            onClick={() => {
                                this.showDrawer();
                                this.setState({ error: false });
                            }} />
                        :
                        <EditTwoTone
                            twoToneColor="#bfbfbf"
                            style={{ fontSize: '15px' }} /> // Si no es manager, bloquea el boton editar solo de aquel usuario que es el administrador ( es decir el manager)
                }
                <Drawer
                    title="Usuario - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Usuario')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                disabled
                                name="IdUsuario"
                                type="text"
                                id="IdUsuario"
                                size="small"
                                value={this.state.usuario && this.state.usuario.IdUsuario}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Nombre"
                            required>
                            <Input
                                name="Nombre"
                                type="text"
                                id="Nombre"
                                minLength={5}
                                maxLength={50}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.usuario && this.state.usuario.Nombre}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El usuario solo debe contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Contraseña"
                            required>
                            <Input
                                name="Contrasenia"
                                type="password"
                                id="Contrasenia"
                                minLength={5}
                                maxLength={16}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.usuario && this.state.usuario.Contrasenia}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionP, 'La contraseña solo debe contener letras, numeros y guiones')}
                        </Form.Item>
                        <Form.Item
                            label="Rol"
                            required
                        >
                            <Select
                                name="idRol"
                                id="idRol"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        usuario: {
                                            ...this.state.usuario,
                                            idRol: selectedGroup
                                        },
                                        validacionS: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.usuario && this.state.usuario.idRol) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Seleccionar rol </label></Option>
                                {this.state.rol.map(elemento => (
                                    <Option key={elemento.idRol} value={elemento.idRol}>{elemento.tipoRol}</Option>
                                ))}
                            </Select>

                            {mostrarMensajeValidacionFooter(this.state.validacionS, 'Debe seleccionar un rol')}

                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion && !this.state.validacionP ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Actualizar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Actualizar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}