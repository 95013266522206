import React, { Component } from 'react'
import { Link } from 'react-router-dom'; //npm install react-router-dom (instalar)
import { Menu } from 'antd'; //npm i antd (instalar)
import {
    PieChartOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined,
    PhoneOutlined,
    ShoppingCartOutlined,
    UsergroupDeleteOutlined,
    CoffeeOutlined,
    TagsOutlined,
    FileTextOutlined,
    FilePdfOutlined,
    ToolOutlined,
    ReadOutlined
} from '@ant-design/icons'; //npm i antd (instalar)

const { SubMenu } = Menu;

export default class MenuAdministrador extends Component {

    render() {
        return (
            <Menu
                theme={this.props.theme}
                defaultSelectedKeys={['1']}
                mode="inline"
            >
                <Menu.Item key="1" icon={<PieChartOutlined />}>
                    <Link to="/">
                        Resumen
                    </Link>
                </Menu.Item>
                <SubMenu key="sub1" icon={<ReadOutlined />} title="Datos Iniciales">
                    <SubMenu key="sub2" icon={<CoffeeOutlined />} title="Maquina">
                        <Menu.Item key="2" icon={<CoffeeOutlined />}>
                            <Link to='ListadoTiposMaquinas'>
                                Tipo
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub3" icon={<PhoneOutlined />} title="Reclamo">
                        <Menu.Item key="3" icon={<PhoneOutlined />}>
                            <Link to='ListadoEstadoReclamos'>
                                Estado
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="4" icon={<PhoneOutlined />}>
                            <Link to='ListadoAsignadoReclamos'>
                                Asignacion
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub4" icon={<FileTextOutlined />} title="H. de Servicio">
                        <Menu.Item key="5" icon={<FileTextOutlined />}>
                            <Link to='ListadoTipoHojasServicio'>
                                Tipo
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub5" icon={<FileTextOutlined />} title="I. de Maquina">
                        <Menu.Item key="6" icon={<FileTextOutlined />}>
                            <Link to='ListadoMotivoIngreso'>
                                Motivo
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="7" icon={<FileTextOutlined />}>
                            <Link to='ListadoEstadoIngresoMaquinas'>
                                Estado
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                </SubMenu>
                <SubMenu key="sub6" icon={<SettingOutlined />} title="Administración">
                    <Menu.Item key="8" icon={<UserOutlined />}>
                        <Link to="ListadoRoles">
                            Roles
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<TeamOutlined />}>
                        <Link to='ListadoUsuarios'>
                            Usuarios
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="10" icon={<TeamOutlined />}>
                        <Link to="ListadoTecnicos">
                            Tecnicos
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="11" icon={<CoffeeOutlined />}>
                        <Link to='ListadoMaquinas'>
                            Maquinas
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="12" icon={<TagsOutlined />}>
                        <Link to='ListadoProductos'>
                            Productos
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="sub7" icon={<ShoppingCartOutlined />} title="Ventas">
                    <Menu.Item key="13" icon={<UsergroupDeleteOutlined />}>
                        <Link to='ListadoClientes'>
                            Clientes
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="sub8" icon={<ToolOutlined />} title="Area Tecnica">
                    <Menu.Item key="14" icon={<PhoneOutlined />}>
                        <Link to='ListadoReclamos'>
                            Reclamos
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="15" icon={<FileTextOutlined />}>
                        <Link to='ListadoHojasServicio'>
                            Hojas Servicio
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="16" icon={<FilePdfOutlined />}>
                        <Link to='ListadoIngresoMaquinas'>
                            Ingreso Maquinas
                        </Link>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        )
    }
}