import React, { Component } from 'react';
import { Drawer, Form, Button, Input, Space } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar) 
import { mostrarAlertaModificar, mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esNombre, primeraMayuscula, quitoEspacios } from '../../Paginas/Validaciones';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class ModificarEstadoReclamo extends Component {
    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar        
        validacion: false,
        error: false,
        estadoReclamo: {
            idEstado: '',
            estado: ''
        }
    };

    showDrawer = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cargarDatos = async () => {
        await this.setState({
            estadoReclamo: {
                idEstado: this.props.filaSeleccionada.idEstado,
                estado: this.props.filaSeleccionada.estado
            }
        });
    }

    obtenerDatos = () => {
        return (
            this.props.datos
        )
    }

    validacionForm = async () => {
        if (esNombre(this.state.estadoReclamo.estado)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            estadoReclamo: {
                ...this.state.estadoReclamo,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => {
        e.preventDefault(); // El preventDefault() método cancela el evento si es cancelable, 
        //lo que significa que la acción predeterminada que pertenece al evento no ocurrirá. 
        //Por ejemplo, esto puede ser útil cuando: Al hacer clic en un "Submit" botón, evitar que enviar un formulario. 
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }}
        if (!expiredToken()) {
            this.state.estadoReclamo.estado = quitoEspacios(this.state.estadoReclamo.estado);
            this.state.estadoReclamo.estado = primeraMayuscula(this.state.estadoReclamo.estado);
            if (this.state.estadoReclamo.estado !== '') {// verifico que el campo tipo.tipo1 no sea vacio                 
                await axios.put('EstadoReclamo', this.state.estadoReclamo, config) // Inserta en la Base de datos el nuevo registro
                    .then(response => {
                        this.onClose(); // Cierra el modal luego del ingreso  
                        this.props.listaEstados(); // Refresca la lista con el registro modificado
                        mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente.                        
                    }).catch(error => {
                        if (error.response) {
                            mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                            this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                        }
                    }).catch(errorServer => {
                        errorServer = mostrarErrorCatch();
                    })
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <EditTwoTone 
                    twoToneColor="#d48806"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ error: false });
                    }} />

                <Drawer
                    title="Estado de Reclamo - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Estado de Reclamo')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Número">
                            <Input
                                disabled
                                name="idEstado"
                                type="text"
                                id="idEstado"
                                size="small"
                                value={this.state.estadoReclamo && this.state.estadoReclamo.idEstado}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Descripción"
                            required>
                            <Input
                                name="estado"
                                type="text"
                                id="estado"
                                minLength={4}
                                maxLength={16}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.estadoReclamo && this.state.estadoReclamo.estado}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El estado del reclamo solo debe contener letras')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Actualizar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Actualizar
                                    </Button>
                                }

                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        );
    }
}