import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, DatePicker, Select } from 'antd'; //npm i antd (instalar)
import { PhoneTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)
import { mostrarAlertaExito, mostrarAlertaError, mostrarErrorCatch, mostrarMensajeValidacionFooter, mostrarMensajeFooter1 } from '../../Paginas/Mensajes';
import { comienzoOracionMayuscula, esNombre, esNombreNumeros, primeraMayuscula, quitoEspacios, todasMayusculas } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class CrearReclamo extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar        
        error: false,
        detalleError: '',
        validacionSector: false,
        validacionContacto: false,
        validacionTelefonoContacto: false,
        validacionDetalle: false,
        validacionResolucion: false,
        validacionSelectTecnico: false,
        validacionSelectAsignado: false,
        validacionSelectMaquina: false,
        reclamo: {
            idReclamo: '',
            fecha: '',
            sector: '',
            contacto: '',
            telefonoContacto: '',
            detalle: '',
            idEstado: '',
            resolucion: "",
            idAsignado: '',
            idCliente: '',
            idMaquina: '',
            idUsuario: '',
            hayHojaServicio: '',
            hayIngresoMaquina: '',
        },
        Cliente: '',
        DireccionCliente: '',
        estado: [],
        asignado: [],
        maquina: []
    };

    showDrawer = () => {
        this.cargarDatos();
        this.obtenerAsignadoSelect();
        this.obtenerMaquinaSelect();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacionSector: false,
            validacionContacto: false,
            validacionTelefonoContacto: false,
            validacionDetalle: false,
            validacionResolucion: false,
            validacionSelectTecnico: false,
            validacionSelectAsignado: false,
            validacionSelectMaquina: false,
        });
    };

    obtenerAsignadoSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de estado
                .get('AsignadoReclamo', config)
                .then(response => {
                    this.setState({ asignado: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    obtenerMaquinaSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de estado
                .get('Maquina', config)
                .then(response => {
                    this.setState({ maquina: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    cargarDatos = async () => {
        await this.setState({
            reclamo: {
                idReclamo: '',
                fecha: moment().format("DD-MM-YYYY"), // Coloca la fecha del dia
                sector: '',
                contacto: '',
                telefonoContacto: '',
                detalle: '',
                idEstado: 1, // Selecciono por defecto el estado 1 (Pendiente)
                resolucion: "",
                idAsignado: '',
                idCliente: this.props.filaSeleccionada.idCliente,
                idMaquina: '',
                idUsuario: Number(sessionStorage.getItem('IdUsuario')),
                hayHojaServicio: false,
                hayIngresoMaquina: false

            },
            Cliente: this.props.filaSeleccionada.nombre,
            DireccionCliente: this.props.filaSeleccionada.direccion,
        });
        this.obtenerAsignadoSelect();
        this.obtenerMaquinaSelect();
    }

    validacionForm = async () => {
        if (!esNombreNumeros(this.state.reclamo.sector)) {
            await this.setState({ validacionSector: false })
        }
        else {
            await this.setState({ validacionSector: true })
        }
        if (esNombre(this.state.reclamo.contacto)) {
            await this.setState({ validacionContacto: false })
        }
        else {
            await this.setState({ validacionContacto: true })
        }
        if (!esNombreNumeros(this.state.reclamo.telefonoContacto)) {
            await this.setState({ validacionTelefonoContacto: false })
        }
        else {
            await this.setState({ validacionTelefonoContacto: true })
        }
        if (!esNombreNumeros(this.state.reclamo.detalle)) {
            await this.setState({ validacionDetalle: false })
        }
        else {
            await this.setState({ validacionDetalle: true })
        }
        if (!esNombreNumeros(this.state.reclamo.resolucion)) {
            await this.setState({ validacionResolucion: false })
        }
        else {
            await this.setState({ validacionResolucion: true })
        }
    }

    validacionSelectEstado = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.reclamo.idEstado === '0' || this.state.reclamo.idEstado === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionSelectAsignado = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.reclamo.idAsignado === '0' || this.state.reclamo.idAsignado === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionSelectMaquina = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.reclamo.idMaquina === '0' || this.state.reclamo.idMaquina === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            reclamo: {
                ...this.state.reclamo,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => { // es llamado al presionar el boton Insertar en el form
        e.preventDefault();
        if (this.state.tipoModal === 'insertar') {
            this.peticionPost();
        }
    }

    conversiones = () => {
        this.state.reclamo.sector = quitoEspacios(this.state.reclamo.sector);
        this.state.reclamo.sector = primeraMayuscula(this.state.reclamo.sector);
        this.state.reclamo.contacto = quitoEspacios(this.state.reclamo.contacto);
        this.state.reclamo.contacto = primeraMayuscula(this.state.reclamo.contacto);
        this.state.reclamo.telefonoContacto = quitoEspacios(this.state.reclamo.telefonoContacto);
        this.state.reclamo.telefonoContacto = todasMayusculas(this.state.reclamo.telefonoContacto);
        this.state.reclamo.detalle = quitoEspacios(this.state.reclamo.detalle);
        this.state.reclamo.detalle = comienzoOracionMayuscula(this.state.reclamo.detalle);
        this.state.reclamo.resolucion = quitoEspacios(this.state.reclamo.resolucion);
        this.state.reclamo.resolucion = comienzoOracionMayuscula(this.state.reclamo.resolucion);
    }

    reverseFecha = async () => {
        let fecham = this.state.reclamo.fecha.split('-').reverse().join('-')
        await this.setState({ // Cago el la fecha invertida 
            reclamo: {
                ...this.state.reclamo,
                fecha: fecham,
            }
        });

        return this.state.reclamo.fecha
    }

    peticionPost = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {                      
            await this.setState({ validacionSelectAsignado: this.validacionSelectAsignado() });
            await this.setState({ validacionSelectMaquina: this.validacionSelectMaquina() });
            if (this.state.validacionSelectAsignado === false) {
                if (this.state.validacionSelectMaquina === false) {
                    this.reverseFecha(); 
                    this.conversiones();
                    delete this.state.reclamo.idReclamo;                    
                    await axios.post('Reclamo', this.state.reclamo, config) // Inserta en la Base de datos el nuevo registro
                        .then(response => {
                            this.props.listaClientes(); // obtengo la lista de clientes pasada por props desde ListadoClientes.js (Al clrear el trabajo vuelve a mostrarme la lista de clientes)
                            this.onClose(); // Cierra el modal luego del ingreso  
                            mostrarAlertaExito(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearPortfolio.js
                        }).catch(error => {
                            console.log(error.response)
                            if (error.response) {                                
                                if (error.response.data.errors.Fecha) { // al tener la fecha un array de errores selecciono el que me interesa desde la api
                                    mostrarAlertaError(error.response.data.errors.Fecha[0])
                                    this.setState({
                                        error: true,
                                        detalleError: error.response.data.errors.Fecha[0]
                                    })
                                }
                            }
                        }).catch(errorServer => {
                            errorServer = mostrarErrorCatch();
                        })
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <PhoneTwoTone
                    twoToneColor="#595959"
                    style={{ fontSize: '15px' }}
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ tipoModal: 'insertar' });
                        this.setState({ error: false });
                    }} />

                <Drawer
                    title="Reclamo - Nuevo"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter1(this.state.error, this.state.detalleError)}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero"
                            hidden
                        >
                            <Input
                                disabled
                                hidden
                                name="idReclamo"
                                type="text"
                                id="idReclamo"
                                size="small"
                                value={this.state.reclamo && this.state.reclamo.idReclamo}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Fecha"
                        >
                            <Input
                                name="fecha"
                                type="text"
                                disabled
                                id="fecha"
                                size="small"
                                value={this.state && this.state.reclamo.fecha}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Cliente"
                        >
                            <Input
                                name="Cliente"
                                type="text"
                                disabled
                                id="Cliente"
                                minLength={5}
                                maxLength={200}
                                size="small"
                                value={this.state && this.state.Cliente}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Direccion"
                        >
                            <Input
                                name="DireccionCliente"
                                type="text"
                                disabled
                                id="DireccionCliente"
                                minLength={5}
                                maxLength={200}
                                size="small"
                                value={this.state && this.state.DireccionCliente}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Sector"
                            required>
                            <Input
                                name="sector"
                                type="text"
                                id="sector"
                                minLength={6}
                                maxLength={200}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.reclamo && this.state.reclamo.sector}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionSector, 'El sector solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Contacto"
                            required>
                            <Input
                                name="contacto"
                                type="text"
                                id="contacto"
                                minLength={6}
                                maxLength={200}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.reclamo && this.state.reclamo.contacto}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionContacto, 'El contacto solo debe contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Telefono"
                            required>
                            <Input
                                name="telefonoContacto"
                                type="text"
                                id="telefonoContacto"
                                minLength={6}
                                maxLength={200}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.reclamo && this.state.reclamo.telefonoContacto}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionTelefonoContacto, 'El telefono solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Maquina"
                            required
                        >
                            <Select
                                name="idMaquina"
                                id="idMaquina"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        reclamo: {
                                            ...this.state.reclamo,
                                            idMaquina: selectedGroup
                                        },
                                        validacionSelectMaquina: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.reclamo && this.state.reclamo.idMaquina) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Seleccionar maquina </label></Option>
                                {this.state.maquina.map(elemento => (
                                    <Option key={elemento.idMaquina} value={elemento.idMaquina}>{elemento.modelo}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionSelectMaquina, 'Debe seleccionar una maquina')}
                        </Form.Item>
                        <Form.Item
                            label="Motivo"
                            required>
                            <TextArea
                                name="detalle"
                                type="text"
                                rows={4}
                                id="detalle"
                                minLength={6}
                                maxLength={500}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.reclamo && this.state.reclamo.detalle}
                                required
                            >
                            </TextArea>
                            {mostrarMensajeValidacionFooter(this.state.validacionDetalle, 'El detalle solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Asignado"
                            required
                        >
                            <Select
                                name="idAsignado"
                                id="idAsignado"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        reclamo: {
                                            ...this.state.reclamo,
                                            idAsignado: selectedGroup
                                        },
                                        validacionSelectAsignado: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.reclamo && this.state.reclamo.idAsignado) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Seleccionar asignacion </label></Option>
                                {this.state.asignado.map(elemento => (
                                    <Option key={elemento.idAsignado} value={elemento.idAsignado}>{elemento.asignado}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionSelectAsignado, 'Debe seleccionar una asignacion de reclamo')}
                        </Form.Item>                        
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacionSector && !this.state.validacionContacto && !this.state.validacionTelefonoContacto &&
                                    !this.state.validacionDetalle && !this.state.validacionResolucion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Insertar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Insertar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}