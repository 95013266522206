import jwtDecode from 'jwt-decode' // npm i jwt-decode
// tengo que usar jwt-decode ya que si uso jsonwebtoken con webpack5 me da muchisimos errores de compatibilidad
// uno de ellos es 'crypto'

export const expiredToken = () => {
    try {
        const token = localStorage.getItem('Token');
        var isExpired = true;
        var decodedToken = jwtDecode(token);
        var dateNow = new Date();
        if (decodedToken != null) {
            if (decodedToken.exp > Math.round(dateNow.getTime() / 1000, 0)) // Le quito los milisegundos a la fecha para poder compararla 
                isExpired = false;
        }
    } catch (error) {
        // console.log(error);
    }

    return isExpired;
};

export const cerrarSesion = () => {
    localStorage.clear();
    return (
        window.location.href = ('/')
    )
}