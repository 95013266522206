import { Component } from "react";
import { Routes, Link, Route } from 'react-router-dom'; //npm install react-router-dom (instalar)
import { UserOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import Logout from "./Logout";
import { expiredToken, cerrarSesion } from './Autorizacion';
import LogoMini from '../Logos/LogoMini.png';
import CafemaxLogo from '../Logos/CafemaxLogo.png';
import WTCPrincipal from '../Logos/WTCPrincipal.png';
import { Navigate } from 'react-router';
import axios from 'axios'; //npm install axios (instalar)
import { Layout, Breadcrumb, Image, Switch, Descriptions } from 'antd'; //npm i antd (instalar)
import { mostrarErrorCatch } from '../Paginas/Mensajes';
import MenuAdministrador from '../MenuXRol/MenuAdministrador';
import MenuTaller from "../MenuXRol/MenuTaller";
import MenuRecepcion from "../MenuXRol/MenuRecepcion";
import ListadoTiposMaquinas from "../Entidades/TiposMaquinas/ListadoTiposMaquinas";
import ListadoEstadoReclamos from "../Entidades/EstadoReclamo/ListadoEstadoReclamos";
import ListadoAsignadoReclamos from "../Entidades/AsignadoReclamo/ListadoAsignadoReclamos";
import ListadoTipoHojasServicio from "../Entidades/TipoHojaServicio/ListadoTipoHojasServicio";
import ListadoMotivoIngreso from "../Entidades/MotivoIngreso/ListadoMotivoIngreso";
import ListadoEstadoIngresoMaquinas from "../Entidades/EstadoIngresoMaquina/ListadoEstadoIngresoMaquinas";
import ListadoRoles from '../Entidades/Roles/ListadoRoles';
import ListadoUsuarios from '../Entidades/Usuarios/ListadoUsuarios';
import ListadoTecnicos from "../Entidades/Tecnicos/ListadoTecnicos";
import ListadoMaquinas from "../Entidades/Maquinas/ListadoMaquinas";
import ListadoClientes from "../Entidades/Clientes/ListadoClientes";
import ListadoReclamos from "../Entidades/Reclamo/ListadoReclamos";
import ListadoReclamosPendientes from "../Entidades/Reclamo/ListadoReclamosPendientes";
import ListadoReclamosEnCurso from "../Entidades/Reclamo/ListadoReclamosEnCurso";
import ListadoHojasServicio from "../Entidades/HojaServicio/ListadoHojasServicio";
import ListadoIngresoMaquinas from "../Entidades/IngresoMaquinas/ListadoIngresoMaquinas";
import Ayuda from "./Ayuda";
import ListadoProductos from "../Entidades/Productos/ListadoProductos";
import ReparacionesPorTecnicoPorMes from "./Graficas/ReparacionesPorTecnicoProMes";
import InformacionTaller from "./Graficas/InformacionTaller";
import InformacionRecepcion from "./Graficas/InformacionRecepcion";


const { Header, Content, Footer, Sider } = Layout;

export default class PaginaPrincipal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            theme: 'dark',
            collapsed: false,
            data: [],
            tipoRol: ''
        }
    };

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    changeTheme = value => {
        this.setState({
            theme: value ? 'dark' : 'light',
        });
    };

    obtenerUsuario = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Usuario/' + localStorage.getItem('Usuario') + '/Nombre', config) //Consulto para obtener el rol y asi ver que menu se muestra de acuredro a cada usuario       
                .then(response => {
                    this.setState({
                        data: response.data.data,
                        tipoRol: response.data.data.unObjRol.tipoRol
                    }); // Cargo en data los resultados de la consulta 
                    sessionStorage.setItem('IdUsuario', response.data.data.idUsuario)
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    componentDidMount() {
        this.obtenerUsuario();
    }

    render() {
        if (expiredToken()) {
            return <Navigate to='/' />
        }

        if (localStorage.getItem('Token') === null) {
            return <Navigate to='/' />
        }

        const { collapsed } = this.state;
        const { theme } = this.state;
        let div;
        let descripcion;
        let menuXrol; // Para saber que usuario esta logeado segun el rol        

        //#region Editar logo, color, y descripcion al minimizar el menu

        //Cambia el tamaño del logo al minimizar el menu, el segundo if camba el color del menu y el del titulo de la descripcion
        if (!collapsed) {
            if (theme === 'dark') {
                div = <div className="logo">
                    <Image
                        width={90}
                        src={LogoMini}
                        preview={false}
                    />
                </div>
                descripcion = <div className="titulo">
                    <Descriptions>
                        <Descriptions.Item
                            contentStyle={{
                                color: "white",
                                fontSize: 12,
                                marginLeft: '40px'
                            }}
                            theme={this.state.theme}>
                            MENU PRINCIPAL
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            }
            else {
                div = <div className="logo">
                    <Image
                        width={90}
                        src={CafemaxLogo}
                        preview={false}
                    />
                </div>
                descripcion = <div className="titulo">
                    <Descriptions>
                        <Descriptions.Item
                            contentStyle={{
                                color: "black",
                                fontSize: 12,
                                marginLeft: '40px'
                            }}
                            theme={this.state.theme}>
                            MENU PRINCIPAL
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            }
        }
        else {
            if (theme === 'dark') {
                div = <div className="logoMiniMenu">
                    <Image
                        width={50}
                        src={LogoMini}
                        preview={false}
                    />
                </div>
                descripcion = <div className="titulo">
                    <Descriptions>
                        <Descriptions.Item
                            contentStyle={{
                                color: "white",
                                fontSize: 12,
                            }}
                            theme={this.state.theme}>
                            MENU PPAL
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            }
            else {
                div = <div className="logoMiniMenu">
                    <Image
                        width={50}
                        src={CafemaxLogo}
                        preview={false}
                    />
                </div>
                descripcion = <div className="titulo">
                    <Descriptions>
                        <Descriptions.Item
                            contentStyle={{
                                color: "black",
                                fontSize: 12,
                            }}
                            theme={this.state.theme}>
                            MENU PPAL
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            }
        }

        if (this.state.tipoRol === 'Administrador') {
            menuXrol = <MenuAdministrador theme={this.state.theme} />
               } else {
                   if (this.state.tipoRol === 'Taller') {
                       menuXrol = <MenuTaller theme={this.state.theme} />
                   } else {
                       if (this.state.tipoRol === 'Recepcion') {
                           menuXrol = <MenuRecepcion theme={this.state.theme} />
                       }
                   }
        }

        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider theme={this.state.theme} collapsible collapsed={collapsed} onCollapse={this.onCollapse} >
                    <Link to="/">
                        {div}
                    </Link>
                    {/* Muestra el titulo y el logo segun color al minimizar el menu (hay un if mas arriba) */}
                    {descripcion}
                    {/* Muestra el menu de acuerdo al usuario logeado*/}
                    {menuXrol}

                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: '25px' }} >
                        <b className='usuarioSesion'><UserOutlined /></b> Usuario: <strong> {this.state.data.nombre} </strong>
                        <Logout />
                    </Header>

                    <Content style={{ margin: '15px 16px' }}>
                        <Breadcrumb style={{ margin: '20px 20px' }}>
                            <Breadcrumb.Item>
                                <Link to="/" style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                                    Inicio
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Ayuda />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Switch
                                    checked={this.state.theme === 'dark'}
                                    onChange={this.changeTheme}
                                    checkedChildren="Oscuro"
                                    unCheckedChildren="Claro"
                                />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>

                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: '100%' }}>
                            <Routes>
                                <Route exact path="/ListadoRoles" element={<ListadoRoles />} key="1" />
                                <Route exact path="/ListadoTiposMaquinas" element={<ListadoTiposMaquinas />} key="2" />
                                <Route exact path="/ListadoEstadoReclamos" element={<ListadoEstadoReclamos />} key="3" />
                                <Route exact path="/ListadoAsignadoReclamos" element={<ListadoAsignadoReclamos />} key="4" />
                                <Route exact path="/ListadoTipoHojasServicio" element={<ListadoTipoHojasServicio />} key="5" />
                                <Route exact path="/ListadoMotivoIngreso" element={<ListadoMotivoIngreso />} key="6" />
                                <Route exact path="/ListadoEstadoIngresoMaquinas" element={<ListadoEstadoIngresoMaquinas />} key="7" />
                                <Route exact path="/ListadoUsuarios" element={<ListadoUsuarios />} key="8" />
                                <Route exact path="/ListadoTecnicos" element={<ListadoTecnicos />} key="9" />
                                <Route exact path="/ListadoMaquinas" element={<ListadoMaquinas />} key="10" />
                                <Route exact path="/ListadoClientes" element={<ListadoClientes />} key="11" />
                                <Route exact path="/ListadoReclamos" element={<ListadoReclamos />} key="12" />
                                <Route exact path="/ListadoReclamosPendientes" element={<ListadoReclamosPendientes />} key="13" />
                                <Route exact path="/ListadoReclamosEnCurso" element={<ListadoReclamosEnCurso />} key="14" />
                                <Route exact path="/Ayuda" component={Ayuda} key="15" />
                                <Route exact path="/ListadoHojasServicio" element={<ListadoHojasServicio />} key="16" />
                                <Route exact path="/ListadoIngresoMaquinas" element={<ListadoIngresoMaquinas />} key="17" />
                                <Route exact path="/ListadoProductos" element={<ListadoProductos />} key= "18" />

                                {(() => { // Encaso de queres colocar mas de 1 renglon dentro de 1 if usar asi (this.state.tipoRol === 'Administrador' ? en este caso solo permite 1 renglon)
                                    if (this.state.tipoRol === 'Administrador') {
                                        return [  
                                            <Route exact path="/" element={<InformacionRecepcion />} key="19" /> ,                                        
                                            <Route exact path="/PaginaPrincipal" element={<InformacionRecepcion />} key="20" />                                            
                                        ]

                                    }
                                    if (this.state.tipoRol === 'Taller') {
                                        return [  
                                            <Route exact path="/" element={<InformacionTaller />} key="21" /> ,                                        
                                            <Route exact path="/PaginaPrincipal" element={<InformacionTaller />} key="22" />                                            
                                        ]

                                    }
                                })()}


                            </Routes>
                        </div>
                    </Content>

                    <Footer style={{ textAlign: 'left' }} >
                        <strong className="tituloPrincipal" >Copyright © 2022-2023
                            <Image
                                className='imagen-footer'
                                src={WTCPrincipal}
                                width={120}
                                preview={false} />
                        </strong>
                        <strong className='tituloPrincipal' >
                            Todos los derechos reservados.
                        </strong>
                        <div className='footerRightPrincipal' >
                            <b>Version</b> 1.1.0
                        </div>
                    </Footer>
                </Layout>
            </Layout>
        )
    }
}