import React, { Component } from 'react'
import '../Estilos/estilos.css';
import { Descriptions, notification } from 'antd'; //npm i antd (instalar)
import { 
    InfoCircleTwoTone, 
    EditTwoTone, 
    DeleteTwoTone, 
    PhoneTwoTone, 
    FileTextTwoTone,    
    } from '@ant-design/icons'; //npm i antd (instalar)

const tab = <>&nbsp;&nbsp;&nbsp;</>;

export default class Ayuda extends Component {

    openNotification = () => {
        const args = {
            description:
                <Descriptions colon='false' size="small" column={1} title="Ayuda - Botones" contentStyle={{ color: "slategray" }}>
                    <Descriptions.Item > <EditTwoTone twoToneColor="#d48806" style={{ fontSize: '15px' }} />{tab} : Editar registros </Descriptions.Item>
                    <Descriptions.Item > <DeleteTwoTone twoToneColor="#cf1322" style={{ fontSize: '15px' }} />{tab} : Borrar registros </Descriptions.Item>
                    <Descriptions.Item> <InfoCircleTwoTone twoToneColor="#7cb305" style={{ fontSize: '15px' }} /> {tab} : Ficha con todos los datos </Descriptions.Item>
                    <Descriptions.Item > <PhoneTwoTone twoToneColor="#595959" style={{ fontSize: '15px' }} />{tab} : Nuevo reclamo asociado al cliente </Descriptions.Item>
                    <Descriptions.Item > <FileTextTwoTone twoToneColor="#1890ff" style = {{fontSize:'15px'}} />{tab} : Nueva hoja de servicio asociada al reclamo </Descriptions.Item>
                </Descriptions>,
            duration: 10,
        };
        return (
            notification.open(args)
        );
    }

    render() {

        return (
            <a onClick={() => this.openNotification()} style={{ color: 'rgba(0, 0, 0, 0.45)' }} >Ayuda</a>
        )
    }
}